import { apolloApi } from "@/shared/api";
import { ReportResponseItem } from "@suns/api/generated-client/apollo";

export async function gameReportLoader({
  gameId,
  authorUsername,
}: {
  gameId: number;
  authorUsername: string;
}) {
  const reportsResponse = await apolloApi.getReports({
    gameId,
    authorUsername: authorUsername ? [authorUsername] : [],
  });

  const reports =
    reportsResponse?.reports?.reduce(
      (acc, report) => {
        acc[`${report.playerId}`] = report;
        return acc;
      },
      {} as Record<string, ReportResponseItem>
    ) ?? {};

  const gameResponse = await apolloApi.getGameById(gameId);

  return { reports, game: gameResponse.game };
}
