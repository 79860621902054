import {
  Dribbble,
  FlaskConical,
  Home,
  LucideProps,
  Pencil,
  Play,
  Users,
} from "@suns/design-system/icons";
import { ForwardRefExoticComponent } from "react";

export interface NavigationItem {
  path: string;
  title: string;
  Icon?: ForwardRefExoticComponent<
    Omit<LucideProps, "ref"> & React.RefAttributes<SVGSVGElement>
  >;
  requiredRoles?: string[];
  showPrinter?: boolean;
  children?: NavigationItem[];
}

/**
 * This file contains the navigation items for the sidebar and the header navigation.
 * Each item is an object with the following properties:
 * - path: the path to navigate to when clicked
 * - title: the title to display in the sidebar and header
 * - Icon: an optional icon component to display next to the title in the sidebar
 * - requiredRoles: an optional array of roles that are required to view the item (see AccountInfo.tsx)
 * - showPrinter: an optional boolean to indicate if the printer icon should be shown in the header on this route
 * - children: an optional array of child navigation items to render in the sidebar and header
 */

export const NAVIGATION: NavigationItem[] = [
  {
    path: "/",
    title: "Home",
    Icon: Home,
  },
  {
    path: "/players",
    title: "Players",
    Icon: Users,
    requiredRoles: ["hasDeveloperAccess"],
    children: [{ path: "players", title: "All Players" }],
  },
  {
    path: "/reports",
    title: "Reports",
    Icon: Pencil,
    requiredRoles: ["hasScoutAccess"],
    children: [
      {
        path: "reports/all-reports",
        title: "All Reports",
        requiredRoles: ["hasScoutAdminAccess"],
      },
      { path: "reports", title: "My Reports" },
      { path: "reports/create", title: "Create Report" },
      { path: "reports/intel", title: "My Intel" },
      { path: "reports/intel/create", title: "Create Intel" },
    ],
  },
  {
    path: "/team",
    title: "Team",
    Icon: Play,
    showPrinter: true,
    children: [
      { path: "team", title: "Team DNA" },
      { path: "box-scores", title: "Boxscore" },
      { path: "box-scores/lineups", title: "Lineups" },
      { path: "box-scores/rebounding", title: "Rebounding" },
      { path: "https://personnel.phxapollo.com/", title: "Personnel" },
    ],
  },
  {
    path: "/game",
    title: "Games",
    Icon: Dribbble,
    children: [{ path: "game", title: "All Games" }],
  },
  {
    path: "/labs",
    title: "Labs",
    Icon: FlaskConical,
    requiredRoles: ["hasBetaShinyAccess"],
    children: [{ path: "labs", title: "Apps" }],
  },
];
