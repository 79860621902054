import { useAccount } from "@/shared/hooks/useAccount";
import {
  Button,
  Card,
  cn,
  Container,
  Flex,
  SkeletonHeading,
  SkeletonText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
} from "@suns/design-system";
import { useAsync } from "@/shared/hooks/useAsync";
import { Link, useNavigate, useParams } from "react-router-dom";
import { viewReportLoader } from "./loaders/view-report-loader";
import { PencilIcon, PrinterIcon, Trash2Icon } from "@suns/design-system/icons";
import {
  PlayerHeader,
  PlayerHeaderSkeleton,
  useNavigateLogin,
} from "@/components";
import { ReportForm } from "../components/ReportForm/ReportForm";
import { URL } from "@/shared/utils/route-constant";
import dayjs from "dayjs";
import Page from "@/components/Page/Page";
import { SunsApiError } from "@suns/api";
import { reportResponseToFormData } from "../report-utils";
import { toast } from "@/shared/utils/toaster";
import { ToastType } from "@/shared/utils/toaster";
import { apolloApi } from "@/shared/api";
import MobilePopoverMenu from "./components/mobile-popover-menu";
import { ReportResponseItem } from "@suns/api/generated-client/apollo";
import { usePrompt } from "@/components/Prompt/Prompt";

export function ReportView() {
  const { reportId } = useParams();
  const account = useAccount();
  const navigateLogin = useNavigateLogin();
  const navigate = useNavigate();
  const prompt = usePrompt();

  const { response, loading, error } = useAsync(viewReportLoader, {
    reportId: reportId || "",
  });

  const handleDelete = async () => {
    const deleteConfirmation = await prompt(
      "Are you sure you want to delete this report?",
      ""
    );

    if (deleteConfirmation) {
      try {
        await apolloApi.deleteReport(Number(reportId));
        toast(ToastType.SUCCESS, "Report deleted");
        navigate(-1);
      } catch (e) {
        if (e instanceof SunsApiError && e.authError) {
          toast(ToastType.ERROR, "Please log back in to delete.");
          navigateLogin();
        }
        toast(ToastType.ERROR, "Error deleting report.");
      }
    }
  };

  return (
    <Page
      title={
        response
          ? response?.report.type === ReportResponseItem.type.PHASE
            ? `${response?.report.player.firstName} ${response?.report.player.lastName} Phase 1, 2024-25`
            : `${response?.report.player.firstName} ${response?.report.player.lastName}`
          : "View Report"
      }
      loading={loading}
      loadingState={<ReportViewLoading />}
      render={() => {
        if (error) {
          throw new SunsApiError("Error loading report", { cause: error });
        }

        if (!account.info) {
          throw new Error("User Authorization Error");
        }

        const {
          report,
          seasonStats,
          gamesStats,
          statsHeaders,
          availableReports,
          nbaApiError,
        } = response!;

        if (!report) {
          throw new Error("Error loading report data", { cause: error });
        }

        const parsedReport = reportResponseToFormData(
          report.player,
          account.info,
          report.type,
          report.games,
          report,
          availableReports
        );

        const team = report.player.currentTeams?.length
          ? report.player.currentTeams?.find(
              (team) => team.id === report.teamId
            )
          : report.player.currentTeams?.[0];

        const firstGame = report.games?.length > 0 ? report.games[0] : null;
        let editLink = `/${URL.REPORTS.path}/${URL.REPORTS.children.EDIT_REPORT.path}/${report.id}`;
        if (
          firstGame &&
          report.authorUsername === account.info?.username &&
          report.type === ReportResponseItem.type.GENERAL
        ) {
          editLink = `/${URL.REPORTS.path}/${URL.REPORTS.children.CREATE_REPORT.path}/game/${firstGame.id}?playerId=${report.player.id}`;
        }

        return (
          <Container size="xl">
            <Card>
              <Flex direction="down" align="center" className="w-full">
                <Flex direction="down" className="w-full max-w-5xl" gap="lg">
                  <Flex
                    className="absolute right-4 hidden sm:flex print:hidden"
                    gap="sm"
                  >
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        window.print();
                      }}
                    >
                      <PrinterIcon size={16} />
                    </Button>
                    <Link to={editLink}>
                      <Button type="button" variant="outline">
                        <Flex direction="right" align="center" gap="sm">
                          <PencilIcon size={16} />
                          Edit
                        </Flex>
                      </Button>
                    </Link>
                    <Button
                      type="button"
                      variant="outline"
                      onClick={handleDelete}
                    >
                      <Flex direction="right" align="center" gap="sm">
                        <Trash2Icon size={16} />
                        Delete
                      </Flex>
                    </Button>
                  </Flex>

                  <MobilePopoverMenu
                    handleDelete={handleDelete}
                    reportId={report.id}
                  />

                  <Flex direction="right" justify="between" align="center">
                    <Flex direction="down" gap="sm">
                      <Text heading size="2xl">
                        {parsedReport.type === ReportResponseItem.type.PHASE
                          ? "Phase 1 Report"
                          : "Scouting report"}
                      </Text>
                      <Flex direction="right" gap="xs">
                        <Text size="sm" heading>
                          {report.authorName}
                        </Text>
                        <Text size="sm" muted>
                          on
                        </Text>
                        <Text size="sm" heading>
                          {dayjs(report.createdAt).format("MMM D, YYYY")}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <PlayerHeader
                    player={report.player}
                    teamNbaId={team?.nbaId ?? undefined}
                    teamName={team?.name}
                    teamImage={team?.image ?? undefined}
                    leagueId={team?.domesticLeagueId}
                  />
                  {(seasonStats ||
                    (gamesStats && gamesStats.length > 0) ||
                    nbaApiError) && (
                    <Table
                      className={cn("rounded border", [
                        "max-md:[&_td]:p-1",
                        "max-md:[&_td]:text-xs",
                        "max-md:[&_th]:p-1",
                        "max-md:[&_th]:text-xs",
                      ])}
                    >
                      <TableHeader>
                        <TableRow>
                          <TableHead />
                          {statsHeaders.map((stat, index) => (
                            <TableHead key={`boxscore-heading-${index}`}>
                              {stat}
                            </TableHead>
                          ))}
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {gamesStats &&
                          gamesStats.map((gameStats, index) => (
                            <TableRow key={`boxscore-row-${index}`}>
                              <TableCell>
                                <Flex wrap gap="xs">
                                  <Flex gap="xs">
                                    {gameStats.awayCode}
                                    {gameStats.awayScore && (
                                      <b>{gameStats.awayScore}</b>
                                    )}
                                    <p>@</p>
                                  </Flex>
                                  {gameStats.homeCode}
                                  {gameStats.homeScore && (
                                    <b>{gameStats.homeScore}</b>
                                  )}
                                </Flex>
                              </TableCell>
                              {gameStats.playerStats ? (
                                gameStats.playerStats.map(
                                  (statValue, statIndex) => (
                                    <TableCell
                                      key={`boxscore-stat-cell-${statIndex}`}
                                    >
                                      {statValue}
                                    </TableCell>
                                  )
                                )
                              ) : (
                                <TableCell
                                  colSpan={statsHeaders.length}
                                  className="bg-gray-100"
                                >
                                  DNP
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                        {seasonStats && (
                          <TableRow>
                            <TableCell>Season</TableCell>
                            {seasonStats.map((stat, index) => (
                              <TableCell key={`boxscore-stat-cell-${index}`}>
                                {stat}
                              </TableCell>
                            ))}
                          </TableRow>
                        )}
                        {nbaApiError && (
                          <TableRow justify="center">
                            <TableCell
                              colSpan={statsHeaders.length + 1}
                              className="w-full bg-gray-100 text-center"
                            >
                              There was an issue retrieving stats
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  )}
                  <Flex
                    className="pointer-events-none"
                    onClickCapture={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <ReportForm
                      readonly={true}
                      report={parsedReport}
                      isSaving={false}
                      isPublishing={false}
                    />
                  </Flex>
                  <Flex className="print:hidden" gap="sm">
                    <Link
                      to={`/${URL.REPORTS.path}/${URL.REPORTS.children.EDIT_REPORT.path}/${report.id}`}
                    >
                      <Button type="button" variant="outline">
                        <Flex direction="right" align="center" gap="sm">
                          <PencilIcon size={16} />
                          Edit
                        </Flex>
                      </Button>
                    </Link>
                    <Button
                      type="button"
                      variant="outline"
                      onClick={handleDelete}
                    >
                      <Flex direction="right" align="center" gap="sm">
                        <Trash2Icon size={16} />
                        Delete
                      </Flex>
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </Card>
          </Container>
        );
      }}
    />
  );
}

function ReportViewLoading() {
  return (
    <Container size="xl">
      <Card>
        <Flex justify="center">
          <Flex className="w-full max-w-4xl" direction="down" gap="lg">
            <Flex direction="down" gap="sm">
              <SkeletonHeading size="3xl" />
              <SkeletonHeading />
            </Flex>
            <PlayerHeaderSkeleton />
            <SkeletonText rows={2} />
            <SkeletonText rows={3} />
          </Flex>
        </Flex>
      </Card>
    </Container>
  );
}
