import { Flex } from "@suns/design-system";
import {
  cn,
  classVariants,
  type VariantParams,
  responsiveVariants,
} from "@suns/design-system/src/utils";
import defaultSrc from "./default.png";
import { ApolloLeagueId } from "@suns/api";
import { useState } from "react";

const sizes = {
  xs: "size-8",
  sm: "size-12",
  md: "size-16",
  lg: "size-20",
  xl: "size-32",
};

const playerHeadshotVariants = classVariants(["overflow-hidden relative"], {
  variants: {
    variant: {
      default: "rounded-full  bg-gray-200",
      profile: "left-4 overflow-visible",
    },
    size: {
      ...sizes,
      ...responsiveVariants("sm", sizes),
      ...responsiveVariants("md", sizes),
      ...responsiveVariants("lg", sizes),
      ...responsiveVariants("xl", sizes),
    },
  },
  defaultVariants: {
    variant: "default",
    size: "md",
  },
});

type PlayerHeadshotProps = React.HTMLAttributes<HTMLDivElement> &
  VariantParams<typeof playerHeadshotVariants> & {
    src: string | null;
    playerId: number | null;
    leagueId?: ApolloLeagueId;
  };

export function PlayerHeadshot({
  src,
  playerId,
  leagueId,
  size,
  variant,
  className,
  ...props
}: PlayerHeadshotProps) {
  const [isLoaded, setIsLoaded] = useState(false);

  let nbaURL;
  if (leagueId == ApolloLeagueId.WNBA) {
    nbaURL = `https://cdn.wnba.com/headshots/wnba/latest/1040x760/${playerId}.png`;
  } else if (leagueId == ApolloLeagueId.GLEAGUE) {
    nbaURL = `https://ak-static.cms.nba.com/wp-content/uploads/headshots/gleague/1040x760/${playerId}.png`;
  } else if (leagueId == ApolloLeagueId.NBA) {
    nbaURL = `https://cdn.nba.com/headshots/nba/latest/1040x760/${playerId}.png`;
  }

  const dxClasses = "absolute bottom-[-33%] left-0 h-auto w-full max-w-none";
  const defaultClasses = "w-[120%] max-w-none";
  const url = nbaURL || src || defaultSrc;

  let imgClasses = src === url ? dxClasses : defaultClasses;
  imgClasses = cn(imgClasses, { hidden: !isLoaded });

  return (
    <Flex
      align="end"
      justify="center"
      className={cn(playerHeadshotVariants({ variant, size }), className)}
      {...props}
    >
      <img src={url} className={imgClasses} onLoad={() => setIsLoaded(true)} />
      {!isLoaded && <div className="absolute h-full w-full bg-gray-200" />}
    </Flex>
  );
}
