import {
  GameRow,
  PlayerRow,
  ReportApolloGrade,
  ReportPlayerAttribute,
  ReportResponseItem,
  ReportScore,
  ReportUpsertParams,
} from "@suns/api/generated-client/apollo";
import { ReportFormSchema } from "./components/ReportForm/ReportForm";
import { AccountInfo } from "@azure/msal-browser";
import { ReportCanDefendKeys, ReportScoreDescriptions } from "./reports-const";
import { dedupeBy } from "@/shared/utils/helper-functions";

const defaultCanDefend = ReportCanDefendKeys.reduce<Record<string, "0">>(
  (acc, key) => {
    acc[key] = "0";
    return acc;
  },
  {}
);

export function reportResponseToFormData(
  player: PlayerRow,
  author: AccountInfo,
  type: ReportResponseItem.type,
  games: GameRow[],
  report?: ReportResponseItem | null,
  availableReports?: ReportResponseItem[]
): ReportFormSchema {
  const scores =
    report?.scores.reduce<Record<string, string | null>>((acc, score) => {
      acc[score.key] = score.value != null ? `${score.value}` : "NA";
      return acc;
    }, {}) || {};

  const playerTeamId = games.length
    ? player.currentTeams?.find(
        (team) =>
          team.id === games[0]?.homeTeamId || team.id === games[0]?.awayTeamId
      )?.id
    : player.currentTeams?.[0]?.id;

  return {
    id: report?.id ?? undefined,
    playerId: report?.playerId ?? player.id,
    teamId: report?.teamId ?? playerTeamId ?? undefined,
    authorUsername: report?.authorUsername ?? author.username,
    authorName: report?.authorName ?? author.name ?? "",
    status: report?.status ?? ReportResponseItem.status.UNPUBLISHED,
    level: report?.level ?? player.level,
    type: type ?? report?.type ?? ReportResponseItem.type.GENERAL,
    scoutLocation: report?.scoutLocation ?? undefined,
    position: report?.position ?? undefined,
    role: report?.role ?? undefined,
    teamFit: report?.teamFit ?? undefined,
    teamFitNotes: report?.teamFitNotes ?? undefined,
    offensiveNotes: report?.offensiveNotes ?? undefined,
    defensiveNotes: report?.defensiveNotes ?? undefined,
    otherNotes: report?.otherNotes ?? undefined,
    apolloGrades:
      report?.apolloGrades?.reduce(
        (acc, grade) => {
          acc[grade.scope] = grade.value;
          return acc;
        },
        {} as Record<string, string>
      ) ?? {},
    playerAttributes: report?.playerAttributes
      .filter((attr) => attr.value)
      .map((attr) => attr.key),
    scores: {
      ...defaultCanDefend,
      ...scores,
    },
    reports: dedupeBy(
      (report?.reports ?? []).concat(availableReports ?? []),
      (report) => report.id
    ),
    games: games ?? [],
    didNotPlay: report?.didNotPlay,
  };
}

export function reportFormDataToUpsertParams(
  data: ReportFormSchema
): ReportUpsertParams {
  return {
    ...data,
    id: data.id ?? undefined,
    authorUsername: data.authorUsername ?? "",
    apolloGrades: Object.entries(data.apolloGrades ?? {})
      .filter(([, value]) => !!value)
      .map(([scope, value]) => ({
        value: value as ReportApolloGrade.value,
        scope: scope as ReportApolloGrade.scope,
        numericalValue:
          Object.keys(ReportApolloGrade.value).indexOf(value as string) + 1,
        numericalMax: Object.keys(ReportApolloGrade.value).length,
      })),
    scores: Object.entries(data.scores ?? {})
      .filter(([, value]) => !!value)
      .map(([key, value]) => {
        return {
          key: key as ReportScore.key,
          value: value === "NA" ? undefined : Number(value),
          max: ReportCanDefendKeys.includes(key as ReportScore.key)
            ? 2
            : undefined,
        };
      }),
    playerAttributes: Object.values(ReportPlayerAttribute.key).map((key) => {
      return {
        key,
        value: data.playerAttributes?.includes(key) ?? false,
      };
    }),
    teamId: data.teamId ?? undefined,
    teamFitNotes: data.teamFitNotes ?? undefined,
    offensiveNotes: data.offensiveNotes ?? undefined,
    defensiveNotes: data.defensiveNotes ?? undefined,
    otherNotes: data.otherNotes ?? undefined,
    status: data.status,
    type: data.type,
    reportIds: data.reports?.map((report) => parseInt(report.id, 10)) ?? [],
    gameIds: data.games?.map((game) => game.id) ?? [],
    didNotPlay: data.didNotPlay ?? undefined,
  };
}

export function getErrorMessage(path: string[] | undefined) {
  if (!path) {
    return "";
  }

  const type = path[0];
  switch (type) {
    case "scoutLocation":
      return "Scout Location";
    case "position":
      return "Position";
    case "role":
      return "Role";
    case "teamFit":
      return "Suns Fit";
    case "apolloGrades":
      return "Apollo Grade";
    case "scores":
      if (path.length > 1) {
        const score = path[1] as ReportScore.key;
        if (Object.values(ReportScore.key).includes(score)) {
          return ReportScoreDescriptions[score].label;
        }
      }
      return "";
    default:
      return "";
  }
}
