import { useCallback, useEffect } from "react";
import { toast, ToastType } from "@/shared/utils/toaster";
import { notify } from "../bugsnag";

const appVersion = __APP_VERSION__;
const versionCheckInterval = 30 * 1000;

export function VersionChecker() {
  const fetchRemoteVersion = useCallback(
    () =>
      fetch(`/app-version.json`, {
        cache: "no-store",
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          return data.version;
        }),
    []
  );

  useEffect(() => {
    const timer = setInterval(() => {
      fetchRemoteVersion()
        .then((remoteVersion) => {
          if (remoteVersion !== appVersion) {
            toast(
              ToastType.ERROR,
              "There's a new version of the application. Apollo may not work as expected until you refresh the page.",
              false,
              "bottom-left"
            );
            clearInterval(timer);
          }
        })
        .catch((error) => notify(error));
    }, versionCheckInterval);

    return () => clearInterval(timer);
  }, [fetchRemoteVersion]);

  return null;
}
