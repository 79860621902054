import { NAVIGATION, NavigationItem } from "@/shared/utils/navigation";
import { Flex, SkeletonText } from "@suns/design-system";
import {
  Breadcrumb,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@suns/design-system/src/components/Breadcrumb/Breadcrumb";
import { Link } from "react-router-dom";

interface PageParams {
  children?: React.ReactNode;
  render?: () => React.ReactNode;
  title?: string;
  breadcrumbs?: boolean;
  loading?: boolean;
  loadingState?: React.ReactNode;
}

interface BreadcrumbItem {
  label: string;
  link: string;
}

function getNavigationPath(navItems: NavigationItem[]): BreadcrumbItem[] {
  const navItem = navItems.find(
    (item) => item.path != "/" && location.pathname.startsWith(item.path)
  );

  if (navItem) {
    if (navItem.children) {
      return [
        {
          label: navItem.title,
          link: navItem.path,
        },
        ...getNavigationPath(navItem.children),
      ];
    } else {
      return [{ label: navItem.title, link: navItem.path }];
    }
  } else {
    return [];
  }
}

export default function Page({
  children,
  render,
  title,
  loading = false,
  loadingState = null,
  breadcrumbs = true,
}: PageParams) {
  if (title) {
    document.title = title;
  }

  let pageBreadcrumbs = null;
  if (breadcrumbs) {
    pageBreadcrumbs = [
      ...getNavigationPath(NAVIGATION),
      { label: title, link: "#" },
    ];
  }

  return (
    <Flex direction="down" gap="sm">
      {pageBreadcrumbs ? (
        <Breadcrumb className="print:hidden">
          <BreadcrumbList>
            {pageBreadcrumbs.map((breadcrumb, i) => (
              <Flex key={`breadcrumb${i}`} align="center" gap="sm">
                <BreadcrumbLink>
                  <Link to={breadcrumb.link}>{breadcrumb.label}</Link>
                </BreadcrumbLink>
                {i < pageBreadcrumbs.length - 1 ? (
                  <BreadcrumbSeparator />
                ) : null}
              </Flex>
            ))}
          </BreadcrumbList>
        </Breadcrumb>
      ) : null}
      {loading ? (
        loadingState ? (
          loadingState
        ) : (
          <SkeletonText />
        )
      ) : children ? (
        children
      ) : render ? (
        render()
      ) : null}
    </Flex>
  );
}
