import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogHeader,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "@suns/design-system/src/components/AlertDialog/AlertDialog";
import React, { useContext, useRef, useState } from "react";

const PromptContext = React.createContext<{
  prompt: (title: string, description: string) => Promise<boolean>;
}>({
  prompt: () => Promise.resolve(false),
});

export function PromptContainer({ children }: { children: React.ReactNode }) {
  const [open, setIsOpen] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const promptRef = useRef<{
    resolve?: (result: boolean) => void;
  }>({});

  const prompt = (title: string, description: string) => {
    const promptPromise = new Promise<boolean>((resolve) => {
      promptRef.current = { resolve };
    });

    setTitle(title);
    setDescription(description);
    setIsOpen(true);

    return promptPromise;
  };

  function handleConfirm() {
    setIsOpen(false);
    promptRef?.current?.resolve?.(true);
  }

  function handleCancel() {
    setIsOpen(false);
    promptRef?.current?.resolve?.(false);
  }

  return (
    <PromptContext.Provider value={{ prompt }}>
      <AlertDialog open={open}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{title}</AlertDialogTitle>
            <AlertDialogDescription>{description}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleCancel}>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirm}>
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {children}
    </PromptContext.Provider>
  );
}

export const usePrompt = () => {
  const promptContext = useContext(PromptContext);
  return promptContext.prompt;
};
