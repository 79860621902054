import {
  ReportApolloGrade,
  ReportResponseItem,
} from "@suns/api/generated-client/apollo";
import { Flex, FormLabel, Text } from "@suns/design-system";
import { useFormState } from "react-hook-form";
import { FormSelect } from "../FormSelect";
import { ApolloGradeValueLabels } from "@/pages/reports/reports-const";

export default function ApolloGrades({ readonly = false }) {
  const formState = useFormState();
  const level = formState.defaultValues?.level ?? ReportResponseItem.level.PRO;
  const type = formState.defaultValues?.type ?? ReportResponseItem.type.GENERAL;

  // readonly Apollo Grades are rendered in the PositionAndRole component
  if (readonly) {
    return null;
  }

  if (level === ReportResponseItem.level.PRO) {
    return (
      <Flex direction="down" gap="sm">
        <Text size="lg" heading asChild>
          <FormLabel>Apollo Grade</FormLabel>
        </Text>
        <Flex direction="right" gap="lg">
          <FormSelect
            title={type === ReportResponseItem.type.PHASE ? "Today" : null}
            name={`apolloGrades.${ReportApolloGrade.scope.CURRENT}`}
            placeholder="Select an option"
            options={Object.values(ReportApolloGrade.value).map((value) => ({
              value,
              label: ApolloGradeValueLabels[value],
            }))}
          />
          {type === ReportResponseItem.type.PHASE ? (
            <FormSelect
              title="Remaining capacity"
              name={`apolloGrades.${ReportApolloGrade.scope.REMAINING_CAPACITY}`}
              placeholder="Select an option"
              options={Object.values(ReportApolloGrade.value).map((value) => ({
                value,
                label: ApolloGradeValueLabels[value],
              }))}
            />
          ) : null}
        </Flex>
      </Flex>
    );
  } else {
    return (
      <Flex direction="right" gap="lg">
        <FormSelect
          title="Ceiling"
          name={`apolloGrades.${ReportApolloGrade.scope.CEILING}`}
          placeholder="Select an option"
          options={Object.values(ReportApolloGrade.value).map((value) => ({
            value,
            label: ApolloGradeValueLabels[value],
          }))}
        />
        <FormSelect
          title="Bullseye"
          name={`apolloGrades.${ReportApolloGrade.scope.BULLSEYE}`}
          placeholder="Select an option"
          options={Object.values(ReportApolloGrade.value).map((value) => ({
            value,
            label: ApolloGradeValueLabels[value],
          }))}
        />
        <FormSelect
          title="Basement"
          name={`apolloGrades.${ReportApolloGrade.scope.BASEMENT}`}
          placeholder="Select an option"
          options={Object.values(ReportApolloGrade.value).map((value) => ({
            value,
            label: ApolloGradeValueLabels[value],
          }))}
        />
      </Flex>
    );
  }
}
