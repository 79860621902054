import { logout } from "@/shared/auth";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

export function NavigateLogin({
  backToHome = false,
}: {
  backToHome?: boolean;
}) {
  const { pathname } = useLocation();

  useEffect(() => {
    logout();
  }, []);
  return (
    <Navigate
      to="/login"
      state={{ from: backToHome ? "/" : pathname }}
      replace
    />
  );
}

export function useNavigateLogin() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return () => {
    logout();

    navigate("/login", {
      state: { from: pathname },
      replace: true,
    });
  };
}
