import { useEffect } from "react";
import { NavigateLogin } from "@/components";

export function Clear() {
  useEffect(() => {
    localStorage.clear();
  }, []);

  return <NavigateLogin backToHome />;
}
