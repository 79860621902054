import { apolloApi } from "@/shared/api";
import {
  ApolloGrade,
  ApolloGradeScope,
  ApolloPhase,
  PlayerPosition,
  PlayerRole,
} from "@suns/api";

export const CURRENT_PHASE = ApolloPhase.PHASE_24_25_1;

export interface AssessmentFields {
  position?: PlayerPosition;
  grade?: ApolloGrade;
  remainingCapacity?: ApolloGrade;
  role?: PlayerRole;
}

export async function assessmentsLoader({
  authorUsernames,
  teamId,
}: {
  authorUsernames: string[];
  teamId: number;
}) {
  const { players } = await apolloApi.getPlayers({
    currentTeams: [teamId.toString()],
    limit: 100,
  });
  const playerIds = players.map((p) => p.id);

  const response = await apolloApi.getAssessments({
    playerIds,
    authorUsernames,
    phases: [CURRENT_PHASE],
  });

  const assessments = response.assessments.reduce<
    Record<number, AssessmentFields & { id: number }>
  >((acc, assessment) => {
    const grade = assessment.apolloGrades?.find(
      (g) => g.scope == ApolloGradeScope.CURRENT
    )?.value;

    const remainingCapacity = assessment.apolloGrades?.find(
      (g) => g.scope == ApolloGradeScope.REMAINING_CAPACITY
    )?.value;

    acc[assessment.playerId] = {
      id: assessment.id,
      grade,
      remainingCapacity,
      role: assessment.role || undefined,
      position: assessment.position || undefined,
    };
    return acc;
  }, {});

  return {
    assessments,
    players,
  };
}

interface SaveAssessmentOptions {
  beforeSave?: () => void;
  afterSave?: (id: number) => void;
}

type SaveAssessmentParams = AssessmentFields & {
  id?: number;
  playerId: number;
  authorUsername: string;
  authorName: string;
};

export async function saveAssessment(
  assessment: SaveAssessmentParams,
  options?: SaveAssessmentOptions
) {
  // Handle apollo grades
  const grades = Object.keys(ApolloGrade);
  const max = grades.length;
  const apolloGrades: {
    value: ApolloGrade;
    scope: ApolloGradeScope;
    numericalValue: number;
    numericalMax: number;
  }[] = [];

  if (assessment.grade) {
    apolloGrades.push({
      value: assessment.grade,
      scope: ApolloGradeScope.CURRENT,
      numericalValue: max - grades.indexOf(assessment.grade),
      numericalMax: max,
    });
    delete assessment.grade;
  }

  if (assessment.remainingCapacity) {
    apolloGrades.push({
      value: assessment.remainingCapacity,
      scope: ApolloGradeScope.REMAINING_CAPACITY,
      numericalValue: max - grades.indexOf(assessment.remainingCapacity),
      numericalMax: max,
    });
    delete assessment.remainingCapacity;
  }

  options?.beforeSave?.();
  const response = await apolloApi.saveAssessment({
    apolloGrades,
    phase: CURRENT_PHASE,
    ...assessment,
  });
  options?.afterSave?.(response.assessment.id);
}
