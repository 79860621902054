/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PlayerCacheItem = {
    id: number;
    firstName: string;
    lastName: string;
    searchKey: string;
    teamCode: string;
    level: PlayerCacheItem.level;
};
export namespace PlayerCacheItem {
    export enum level {
        PRO = 'PRO',
        AMATEUR = 'AMATEUR',
    }
}

