import { TeamLogo } from "@/components";
import { GameRow } from "@suns/api/generated-client/apollo";
import { Button, cn, Flex, Grid, Skeleton, Text } from "@suns/design-system";
import dayjs from "dayjs";

interface GameSelectionProps {
  games: GameRow[];
  loading: boolean;
  handleSelectGame: (game: GameRow) => void;
  selectedGame: GameRow | undefined;
}

export default function GameSelection({
  games,
  loading,
  handleSelectGame,
  selectedGame,
}: GameSelectionProps) {
  return (
    <>
      {loading ? (
        <Grid gap="md" columns="4" className="pt-3">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
            <Skeleton key={item} className="h-20 w-full" />
          ))}
        </Grid>
      ) : (
        <>
          {games?.length ? (
            <>
              <Grid
                gap="md"
                columns={["md:2", "lg:3", "xl:4"]}
                className="mt-4"
              >
                {games?.map((game) => (
                  <Button
                    variant="outline"
                    onClick={() => handleSelectGame(game)}
                    className={cn(
                      "cursor-pointer py-16 hover:bg-gray-100",
                      selectedGame?.id === game.id &&
                        "border-2 border-black bg-gray-100"
                    )}
                    key={game.id}
                  >
                    <Flex direction="down" justify="center" align="center">
                      <Text
                        className="rounded-xl bg-gray-200 px-2 py-1 font-medium text-gray-700"
                        size="xs"
                        heading
                      >
                        {dayjs(game.gameTimeUTC)
                          .tz(dayjs.tz.guess() ?? "America/New_York")
                          .format("h:mma z")}
                      </Text>
                      <Flex className="py-2" justify="center" align="center">
                        <Flex gap="sm" justify="center" align="center">
                          <TeamLogo
                            image={game.awayTeam?.image}
                            teamId={game.awayTeam?.nbaId}
                            leagueId={game.leagueId}
                            size={"xs"}
                          />
                          <Text heading size="sm">
                            {game.awayTeam?.code ?? game.awayTeam?.name}
                          </Text>
                        </Flex>
                        <Text className="px-2" size="sm">
                          @
                        </Text>
                        <Flex gap="sm" justify="center" align="center">
                          <Text heading size="sm">
                            {game.homeTeam?.code ?? game.homeTeam?.name}
                          </Text>
                          <TeamLogo
                            image={game.homeTeam?.image}
                            teamId={game.homeTeam?.nbaId}
                            leagueId={game.leagueId}
                            size={"xs"}
                          />
                        </Flex>
                      </Flex>
                      <Text size="xs" className="text-gray-500">
                        {game.arenaName}
                      </Text>
                    </Flex>
                  </Button>
                ))}
              </Grid>
            </>
          ) : (
            <Flex className="my-10" justify="center">
              <Text size="md" muted>
                No games are available for the selected date.
              </Text>
            </Flex>
          )}
        </>
      )}
    </>
  );
}
