import {
  PencilIcon,
  Trash2Icon,
  AlignJustifyIcon,
} from "@suns/design-system/icons";
import { Button, Flex, Popover, PopoverContent } from "@suns/design-system";
import { URL } from "@/shared/utils/route-constant";
import { PopoverTrigger } from "@suns/design-system/src/components/Popover/Popover";
import { Link } from "react-router-dom";

export default function MobilePopoverMenu({
  handleDelete,
  reportId,
}: {
  handleDelete: () => void;
  reportId: number;
}) {
  return (
    <Flex className="absolute right-2 sm:hidden print:hidden">
      <Popover>
        <PopoverTrigger asChild>
          <Button type="button" variant="outline" className="p-2">
            <AlignJustifyIcon className="h-5 w-5" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="mr-5 w-32">
          <Flex direction="down" gap="sm">
            <Link
              to={`/${URL.REPORTS.path}/${URL.REPORTS.children.EDIT_REPORT.path}/${reportId}`}
            >
              <Button type="button" variant="ghost">
                <Flex direction="right" align="center" gap="sm">
                  <PencilIcon size={16} />
                  Edit
                </Flex>
              </Button>
            </Link>
            <Button
              type="button"
              variant="ghost"
              className="text-red-500"
              onClick={handleDelete}
            >
              <Flex direction="right" align="center" gap="sm">
                <Trash2Icon size={16} />
                Delete
              </Flex>
            </Button>
          </Flex>
        </PopoverContent>
      </Popover>
    </Flex>
  );
}
