import Page from "@/components/Page/Page";
import { viewPlayerLoader } from "./loaders/view-player-loader";
import { useAsync } from "@/shared/hooks";
import { SunsApiError } from "@suns/api";
import { Card, Flex, Text } from "@suns/design-system";
import { PlayerHeadshot, TeamLogo } from "@/components";
import { useParams } from "react-router-dom";
import { PlayerMeasurements } from "@/components/PlayerMeasurements/PlayerMeasurements";

export function PlayersView() {
  const { playerId } = useParams();
  const { response, loading, error } = useAsync(viewPlayerLoader, {
    playerId: Number(playerId),
  });
  return (
    <Page
      title={
        response
          ? `${response?.player.firstName} ${response?.player.lastName}`
          : "View Player"
      }
      loading={loading}
      render={() => {
        if (error) {
          throw new SunsApiError("Error loading report", { cause: error });
        }

        const { player } = response!;

        return (
          <Card className="overflow-hidden p-2">
            <Flex gap="lg">
              {/* Desktop only */}
              <div className="relative max-sm:hidden">
                <div className="z-1 absolute -bottom-20 -left-20 size-52 opacity-30">
                  <TeamLogo
                    teamId={player.currentTeams?.[0]?.nbaId}
                    leagueId={player.currentTeams?.[0]?.domesticLeagueId}
                    image={player.currentTeams?.[0]?.image}
                    className="h-full w-full"
                  />
                </div>
                <PlayerHeadshot
                  src={player.image}
                  playerId={player.nbaId}
                  leagueId={player.currentTeams?.[0]?.domesticLeagueId}
                  size="xl"
                  variant="profile"
                  className="-mb-4"
                />
              </div>
              <Flex gap="md" direction="down">
                <Flex
                  align="center"
                  gap="lg"
                  className="relative overflow-hidden max-sm:-ml-4"
                >
                  <div className="sm:hidden">
                    <div className="z-1 absolute -bottom-10 -left-10 size-40 opacity-30 sm:hidden">
                      <TeamLogo
                        teamId={player.currentTeams?.[0]?.nbaId}
                        leagueId={player.currentTeams?.[0]?.domesticLeagueId}
                        image={player.currentTeams?.[0]?.image}
                        className="h-full w-full"
                      />
                    </div>
                    <PlayerHeadshot
                      src={player.image}
                      playerId={player.nbaId}
                      leagueId={player.currentTeams?.[0]?.domesticLeagueId}
                      size="xl"
                      variant="profile"
                    />
                  </div>
                  <Flex gap="xs" direction="down">
                    <Text size="2xl" heading>
                      {player.firstName} {player.lastName}
                    </Text>
                    <Flex direction="right" gap="xs" align="center">
                      {player.nbaId || player.currentTeams?.[0]?.image ? (
                        <TeamLogo
                          image={player.currentTeams?.[0]?.image}
                          teamId={player.currentTeams?.[0]?.nbaId}
                          leagueId={player.currentTeams?.[0]?.domesticLeagueId}
                          size="xxs"
                        />
                      ) : (
                        <div className="h-3" />
                      )}
                      <Text size="xs">
                        {player.currentTeams?.[0]?.fullName ?? "Free Agent"}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                <PlayerMeasurements player={player} />
              </Flex>
            </Flex>
          </Card>
        );
      }}
    />
  );
}
