import { useAccount } from "@/shared/hooks/useAccount";
import {
  Card,
  Flex,
  SkeletonText,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Text,
} from "@suns/design-system";
import { useAsync } from "@/shared/hooks/useAsync";
import { myReportsLoader } from "./loaders/my-reports-loader";
import ReportListItem from "./components/ReportListItem";
import { Separator } from "@suns/design-system/src/components/Separator/Separator";
import { ReportQueryParams } from "@suns/api/generated-client/apollo";
import { useEffect, useState } from "react";
import { PaginationControl } from "@/components";
import { SunsApiError } from "@suns/api";
import { defaultFilterValues } from "@/shared/const";
import { PlayerLevel } from "@/pages/intel/intel-create-player-team/tags";
import { FilterValues } from "./all-reports-listings";
import { useSearchParams } from "react-router-dom";
import ReportFilters from "./components/ReportFilters";
import { usePlayerList } from "../reports-create/hooks/usePlayerList";
import { useTeamList } from "@/components/PlayerTeamSearch/hooks/useTeamList";

const PAGE_SIZE = 20;

export function ReportsListings() {
  const account = useAccount();
  const [searchParams, setSearchParams] = useSearchParams();
  const { response: playerList, loading: playerListLoading } = usePlayerList();

  const getParamsFromUrl = () => ({
    level: searchParams.get("level") || PlayerLevel.PRO,
    teamId: searchParams.getAll("teamId") || undefined,
    playerId: searchParams.getAll("playerId")?.[0] || undefined,
  });

  const [activeTab, setActiveTab] = useState("published");
  const [publishedPage, setPublishedPage] = useState(0);
  const [unPublishedPage, setUnpublishedPage] = useState(0);

  const [filterValues, setFilterValues] =
    useState<FilterValues>(getParamsFromUrl());

  const {
    loading: publishedLoading,
    response: publishedReponse,
    refresh: refreshPublished,
    error: publishedError,
  } = useAsync(myReportsLoader, {
    authorUsername: account.info?.username ? [account.info?.username] : [],
    status: ReportQueryParams.status.PUBLISHED,
    offset: publishedPage * PAGE_SIZE,
    limit: PAGE_SIZE,
    teamId: filterValues.teamId,
    playerId: filterValues.playerId,
  });

  const {
    loading: unPublishedLoading,
    response: unPublishedReponse,
    refresh: refreshUnPublished,
    error: unPublishedError,
  } = useAsync(myReportsLoader, {
    authorUsername: account.info?.username ? [account.info?.username] : [],
    status: ReportQueryParams.status.UNPUBLISHED,
    offset: unPublishedPage * PAGE_SIZE,
    limit: PAGE_SIZE,
    teamId: filterValues.teamId,
    playerId: filterValues.playerId,
  });

  const {
    response: teamList,
    error: teamListError,
    loading: teamListLoading,
  } = useTeamList();

  if (publishedError) {
    throw new SunsApiError("Error loading the published reports.", {
      cause: publishedError,
    });
  }

  if (unPublishedError) {
    throw new SunsApiError("Error loading the unpublished reports.", {
      cause: unPublishedError,
    });
  }

  if (teamListError) {
    throw new SunsApiError("Error loading the teams.", {
      cause: teamListError,
    });
  }

  const currentPublishedPage = Math.floor(
    (publishedReponse?.offset || 0) / (publishedReponse?.limit || 1)
  );
  if (publishedPage !== currentPublishedPage) {
    window.scrollTo({ top: 0 });
    refreshPublished();
  }

  const currentUnPublishedPage = Math.floor(
    (unPublishedReponse?.offset || 0) / (unPublishedReponse?.limit || 1)
  );
  if (unPublishedPage !== currentUnPublishedPage) {
    window.scrollTo({ top: 0 });
    refreshUnPublished();
  }

  const handleChangeFilterValues = (value: FilterValues) => {
    setPublishedPage(0);
    setUnpublishedPage(0);
    setFilterValues({ ...filterValues, ...value });

    const searchParams = {
      ...filterValues,
      ...value,
    };

    const filteredSearchParams = Object.entries(searchParams)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, v]) => v !== undefined)
      .reduce((acc, [key, values]) => {
        if (values) {
          if (Array.isArray(values)) {
            values.forEach((value) => acc.append(key, value));
          } else {
            acc.append(key, values.toString());
          }
        }
        return acc;
      }, new URLSearchParams());

    setSearchParams(filteredSearchParams);
  };

  const handleClear = () => {
    setSearchParams({});
    setFilterValues(defaultFilterValues);
  };

  useEffect(() => {
    const urlParams = getParamsFromUrl();
    setFilterValues(urlParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Card
      header={
        <Text size="xl" heading>
          My Reports
        </Text>
      }
    >
      <Tabs onValueChange={setActiveTab} value={activeTab}>
        <TabsList>
          <TabsTrigger value="published">
            <Text>Published</Text>
          </TabsTrigger>
          <TabsTrigger value="unpublished">
            <Text>Unpublished</Text>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="published">
          <ReportFilters
            type="myReportsIntel"
            onChange={handleChangeFilterValues}
            onClear={handleClear}
            filterValues={filterValues}
            teams={teamList || []}
            loading={teamListLoading}
            playerList={playerList || []}
            playerListLoading={playerListLoading}
          />
          {publishedLoading ? (
            <ReportsListingsLoading />
          ) : (
            <>
              {publishedReponse && publishedReponse?.reports?.length > 0 ? (
                publishedReponse?.reports.map((report) => (
                  <Flex direction="down" key={report.id}>
                    <ReportListItem report={report} />
                    <Separator />
                  </Flex>
                ))
              ) : (
                <Text className="py-10 text-center" size="lg" muted>
                  No reports found.
                </Text>
              )}
              <PaginationControl
                {...(publishedReponse || {
                  offset: 0,
                  limit: PAGE_SIZE,
                  count: 0,
                })}
                onPageClicked={(page) => {
                  setPublishedPage(page - 1);
                }}
              />
            </>
          )}
        </TabsContent>
        <TabsContent value="unpublished">
          <ReportFilters
            type="myReportsIntel"
            onChange={handleChangeFilterValues}
            onClear={handleClear}
            filterValues={filterValues}
            teams={teamList || []}
            loading={teamListLoading}
            playerList={playerList || []}
            playerListLoading={playerListLoading}
          />
          {unPublishedLoading ? (
            <ReportsListingsLoading />
          ) : (
            <>
              {unPublishedReponse && unPublishedReponse?.reports?.length > 0 ? (
                unPublishedReponse.reports.map((report) => (
                  <Flex direction="down" key={report.id}>
                    <ReportListItem report={report} />
                    <Separator />
                  </Flex>
                ))
              ) : (
                <Text className="py-10 text-center" size="lg" muted>
                  No reports found.
                </Text>
              )}
              <PaginationControl
                {...(unPublishedReponse || {
                  offset: 0,
                  limit: PAGE_SIZE,
                  count: 0,
                })}
                onPageClicked={(page) => {
                  setUnpublishedPage(page - 1);
                }}
              />
            </>
          )}
        </TabsContent>
      </Tabs>
    </Card>
  );
}

function ReportsListingsLoading() {
  return (
    <Flex direction="down" gap="lg">
      <SkeletonText />
      <SkeletonText />
    </Flex>
  );
}
