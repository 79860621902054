import { apolloApi } from "@/shared/api";

export async function playersLoader({
  offset,
  limit,
  sortColumn,
  sortDir,
}: {
  offset: number;
  limit: number;
  sortColumn: string;
  sortDir: string;
}) {
  const response = await apolloApi.getPlayers({
    offset,
    limit,
    sortColumn,
    sortDir,
  });

  return response;
}
