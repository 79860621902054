import { Button, Flex, Select, SelectOption, Text } from "@suns/design-system";
import { Table } from "@tanstack/react-table";
import {
  ChevronFirstIcon,
  ChevronLastIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "lucide-react";

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
}

export function DataTablePagination<TData>({
  table,
}: DataTablePaginationProps<TData>) {
  return (
    <Flex direction="right" justify="between" align="center">
      <Flex />
      <Flex direction="right" gap="md" align="center">
        <Flex gap="md" align="center">
          <Text size="sm">Rows per page</Text>
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={(value) => {
              table.setPageSize(Number(value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <SelectOption key={pageSize} value={`${pageSize}`}>
                {pageSize}
              </SelectOption>
            ))}
          </Select>
        </Flex>
        <Flex justify="center" align="center">
          <Text size="sm" heading>
            Page {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </Text>
        </Flex>
        <Flex align="center" gap="xs">
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <Text className="sr-only">Go to first page</Text>
            <ChevronFirstIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <Text className="sr-only">Go to previous page</Text>
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <Text className="sr-only">Go to next page</Text>
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <Text className="sr-only">Go to last page</Text>
            <ChevronLastIcon className="h-4 w-4" />
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
