import { apolloApi } from "@/shared/api";

export async function intelByIdLoader({ intelId }: { intelId?: number }) {
  if (!intelId) {
    return null;
  }

  const response = await apolloApi.getIntelById(intelId);

  return response?.intel || null;
}
