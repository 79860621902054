/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type StartTypesResponse = {
    startType: StartTypesResponse.startType;
    possessions: number;
    share: number;
    ortg: number | null;
    efgPct: number | null;
    qsp: number | null;
};
export namespace StartTypesResponse {
    export enum startType {
        FGM_FTM = 'FGM_FTM',
        DREB = 'DREB',
        DEFOB = 'DEFOB',
        SLOB = 'SLOB',
        TO = 'TO',
    }
}

