import { apolloApi } from "@/shared/api";
import { IntelQueryParams } from "@suns/api/generated-client/apollo";

export async function intelLoader({
  playerIds,
  teamIds,
  authorUsername,
}: {
  playerIds?: string[];
  teamIds?: number[];
  authorUsername?: string[];
}) {
  const response = await apolloApi.getIntel({
    playerIds,
    teamIds,
    authorUsername: authorUsername ?? undefined,
    status: IntelQueryParams.status.UNPUBLISHED,
  });

  return response?.intel[0] || null;
}
