import {
  GameRow,
  ReportQueryParams,
  ReportResponseItem,
} from "@suns/api/generated-client/apollo";
import { apolloApi, queryPlayers } from "@/shared/api";
import { ApolloLeagueId } from "@suns/api";
import { getNbaLeagueIdFromNbaGameId } from "@/shared/utils";
import { getNbaLeagueIdFromApolloLeagueId } from "@/shared/utils/league-id";
import { StatName } from "@/shared/const/glossary";
import { LeagueId, SeasonType } from "@suns/api/src/params";
import { notify } from "@/components/bugsnag";

interface PlayerGameStats {
  awayCode: string;
  homeCode: string;
  awayScore?: string;
  homeScore?: string;
  playerStats?: (string | number)[];
}

const statsHeaders = [
  StatName.Min,
  StatName.PTS,
  StatName.FG,
  StatName.FGPct,
  StatName.ThreeP,
  StatName.ThreePPct,
  StatName.FT,
  StatName.FTPct,
  StatName.REB,
  StatName.AST,
  StatName.BLK,
  StatName.TOV,
  StatName.STL,
  StatName.PlusMinus,
];

const seasonStatHeaders = [StatName.GP, StatName.GS];

export async function viewReportLoader({ reportId }: { reportId: string }) {
  const { report } = await apolloApi.getReport(parseInt(reportId, 10));

  const playerCurrentTeamId = report.player.currentTeams?.length
    ? report.player.currentTeams?.find((team) => team.id === report.teamId)
    : report.player.currentTeams?.[0];

  const canGetStatsForLeague =
    playerCurrentTeamId &&
    [ApolloLeagueId.NBA, ApolloLeagueId.WNBA].includes(
      playerCurrentTeamId.domesticLeagueId
    );

  if (
    report.type != ReportResponseItem.type.PHASE &&
    (!report.games.length || !canGetStatsForLeague)
  )
    return { report };

  if (report.type != ReportResponseItem.type.PHASE) {
    try {
      const gamesStats = await getGamesStats(
        String(report.player.nbaId),
        report.games
      );
      return { report, gamesStats, statsHeaders };
    } catch (error) {
      notify(error as Error);
      return { report, nbaApiError: true, statsHeaders };
    }
  }

  const seasonStatsRequest = canGetStatsForLeague
    ? getSeasonStats(
        String(report.player.nbaId),
        report.createdAt,
        playerCurrentTeamId?.domesticLeagueId
      ).catch((error) => {
        notify(error as Error);
        return null;
      })
    : null;

  // get all published general reports for the player by the author
  const associatedReports = await apolloApi.getReports({
    authorUsername: [report?.authorUsername ?? ""],
    status: ReportQueryParams.status.PUBLISHED,
    playerId: `${report?.playerId}`,
    type: ReportResponseItem.type.GENERAL,
    limit: 1000,
  });

  const seasonStatsResponse = await seasonStatsRequest;

  const combinedStatsHeaders = seasonStatHeaders.concat(statsHeaders);

  return {
    report,
    nbaApiError: !seasonStatsResponse && canGetStatsForLeague,
    seasonStats: seasonStatsResponse,
    statsHeaders: combinedStatsHeaders,
    availableReports: associatedReports.reports,
  };
}

async function getGamesStats(playerNbaId: string, games: GameRow[]) {
  const filteredGames = games.filter((game) => {
    const leagueId = getNbaLeagueIdFromNbaGameId(game.nbaId ?? "");
    return leagueId === LeagueId.NBA || leagueId === LeagueId.Wnba;
  });
  const gamesByType = filteredGames.reduce<Record<string, GameRow[]>>(
    (acc, game) => {
      const apolloGameType = game.gameType;
      if (!game.nbaId || !apolloGameType) return acc;
      const nbaGameType = apolloGameTypeToNbaGameType[apolloGameType];

      if (!acc[nbaGameType]) {
        acc[nbaGameType] = [];
      }
      acc[nbaGameType].push(game);
      return acc;
    },
    {}
  );

  const gamesStatsPromises = [];
  for (const [gameType, apolloGames] of Object.entries(gamesByType)) {
    if (!apolloGames[0].nbaId || !apolloGames[0].gameTimeUTC) continue;

    gamesStatsPromises.push(
      queryPlayers({
        scope: "game",
        leagueId: getNbaLeagueIdFromNbaGameId(apolloGames[0].nbaId),
        seasonYear: convertDateToSeason(apolloGames[0].gameTimeUTC),
        seasonType: gameType as SeasonType,
        grouping: "None",
        teamGrouping: "N",
        gameId: apolloGames
          .map((game) => game.nbaId)
          .filter((id) => id !== null) as string[],
        playerId: parseInt(playerNbaId),
        measureType: "Base",
      })
    );
  }
  const gamesStatsResponses = await Promise.all(gamesStatsPromises);

  const parsedGamesStats: PlayerGameStats[] = [];
  for (const apolloGame of games) {
    if (!apolloGame.awayTeam?.code || !apolloGame.homeTeam?.code) continue;

    const gameStats = gamesStatsResponses
      .find((response) =>
        response.data?.players?.some(
          (player) => player.gameId === apolloGame?.nbaId
        )
      )
      ?.data?.players?.find((player) => player.gameId === apolloGame.nbaId);

    if (
      !gameStats?.stats ||
      !gameStats?.teamScore ||
      !gameStats?.opponentScore
    ) {
      parsedGamesStats.push({
        awayCode: apolloGame.awayTeam?.code,
        homeCode: apolloGame.homeTeam?.code,
      });
      continue;
    }

    const parsedGameStats: PlayerGameStats = {
      awayCode: apolloGame.awayTeam?.code,
      homeCode: apolloGame.homeTeam?.code,
      awayScore:
        gameStats.teamId == apolloGame.awayTeam?.nbaId
          ? gameStats.teamScore
          : gameStats.opponentScore,
      homeScore:
        gameStats.teamId == apolloGame.homeTeam?.nbaId
          ? gameStats.teamScore
          : gameStats.opponentScore,
      playerStats: parsePlayerStats(gameStats.stats),
    };
    parsedGamesStats.push(parsedGameStats);
  }
  return parsedGamesStats;
}

async function getSeasonStats(
  playerNbaId: string,
  reportCreatedAt: string,
  leagueId?: number
) {
  if (!leagueId) return;
  const nbaLeagueId = getNbaLeagueIdFromApolloLeagueId(leagueId);
  if (!nbaLeagueId) return;

  const seasonYear = convertDateToSeason(reportCreatedAt);
  const seasonStatsResponse = await queryPlayers({
    scope: "season",
    leagueId: nbaLeagueId,
    seasonYear: seasonYear,
    seasonType: "Regular Season",
    grouping: "None",
    teamGrouping: "N",
    playerId: parseInt(playerNbaId),
    measureType: "Base",
    perMode: "PerGame",
  });

  const seasonStats = seasonStatsResponse.data?.players?.[0]?.stats;
  if (!seasonStats) return;

  return parsePlayerStats(seasonStats, false);
}

const apolloGameTypeToNbaGameType: Record<string, SeasonType> = {
  Regular: "Regular Season",
  "Pre Season": "Preseason",
  Playoff: "Playoffs",
};

function parsePlayerStats(stats: Record<string, number>, perGame = true) {
  let result = [
    Math.round((stats.MIN ?? stats.MIN_PG ?? 0) * 10) / 10,
    stats.PTS ?? stats.PTS_PG,
    (stats.FGA ?? stats.FGA_PG) == 0
      ? "--"
      : (stats.FGM ?? stats.FGM_PG) + "/" + (stats.FGA ?? stats.FGA_PG),
    (stats.FGA ?? stats.FGA_PG) == 0
      ? "--"
      : (stats.FG_PCT ?? stats.FG_PCT_PG) + "%",
    (stats.FG3A ?? stats.FG3A_PG) == 0
      ? "--"
      : (stats.FG3M ?? stats.FG3M_PG) + "/" + (stats.FG3A ?? stats.FG3A_PG),
    (stats.FG3A ?? stats.FG3A_PG) == 0
      ? "--"
      : (stats.FG3_PCT ?? stats.FG3_PCT_PG) + "%",
    (stats.FTA ?? stats.FTA_PG) == 0
      ? "--"
      : (stats.FTM ?? stats.FTM_PG) + "/" + (stats.FTA ?? stats.FTA_PG),
    (stats.FTA ?? stats.FTA_PG) == 0
      ? "--"
      : (stats.FT_PCT ?? stats.FT_PCT_PG) + "%",
    stats.REB ?? stats.REB_PG,
    stats.AST ?? stats.AST_PG,
    stats.BLK ?? stats.BLK_PG,
    stats.TOV ?? stats.TOV_PG,
    stats.STL ?? stats.STL_PG,
    formatPlusMinus(stats.PLUS_MINUS ?? stats.PLUS_MINUS_PG),
  ];

  if (!perGame) {
    result = [stats.GP, stats.STARTED, ...result];
  }

  return result;
}

function formatPlusMinus(stat: number) {
  if (stat > 0) return "+" + stat;
  return stat;
}

function convertDateToSeason(dateString: string): string {
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();

  if (month >= 5) {
    return `${year}-${String(year + 1).slice(-2)}`;
  } else {
    return `${year - 1}-${String(year).slice(-2)}`;
  }
}
