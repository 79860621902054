/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ReportQueryParams = {
    offset?: number;
    limit?: number;
    sortColumn?: string;
    sortDir?: string;
    ids?: Array<number>;
    playerId?: string;
    teamId?: Array<string>;
    level?: ReportQueryParams.level;
    gameId?: number;
    authorUsername?: Array<string>;
    status?: ReportQueryParams.status;
    noAssociatedPhaseReport?: boolean;
    type?: ReportQueryParams.type;
    include?: Array<string>;
    phaseReportId?: number;
};
export namespace ReportQueryParams {
    export enum level {
        PRO = 'PRO',
        AMATEUR = 'AMATEUR',
    }
    export enum status {
        UNPUBLISHED = 'UNPUBLISHED',
        PUBLISHED = 'PUBLISHED',
    }
    export enum type {
        PHASE = 'PHASE',
        GENERAL = 'GENERAL',
    }
}

