import {
  ReportScore,
  ReportApolloGrade,
  ReportPlayerAttribute,
  ReportResponseItem,
} from "@suns/api/generated-client/apollo";

export const SkillScoreSections = {
  OFFENSE: [
    {
      title: "Skill",
      scores: [
        ReportScore.key.SCORING,
        ReportScore.key.PASSING,
        ReportScore.key.PR_PLAYMAKING,
        ReportScore.key.FINISHING,
        ReportScore.key.OFFENSIVE_REBOUNDING,
        ReportScore.key.PR_SCREENING,
      ],
    },
    {
      title: "Shooting",
      scores: [
        ReportScore.key.CS_SHOOTING,
        ReportScore.key.MOVEMENT_SHOOTING,
        ReportScore.key.OFF_DRIBBLE_SHOOTING,
      ],
    },
  ],
  DEFENSE: [
    {
      title: "On-ball",
      scores: [
        ReportScore.key.ON_BALL_DEFENSE,
        ReportScore.key.PR_DEFENSE,
        ReportScore.key.DEFENSIVE_VERSATILITY,
      ],
    },
    {
      title: "Off-ball",
      scores: [
        ReportScore.key.OFF_BALL_DEFENSE,
        ReportScore.key.DEFENSIVE_REBOUNDING,
        ReportScore.key.RIM_PROTECTION,
      ],
    },
  ],
  OTHER: [
    {
      title: null,
      scores: [
        ReportScore.key.BASKETBALL_IQ,
        ReportScore.key.VERTICAL_ATHLETICISM,
        ReportScore.key.AGILITY,
      ],
    },
  ],
};

export const ReportScoreDescriptions = {
  [ReportScore.key.SCORING]: {
    label: "Scorer",
    description:
      "Natural ability to manufacture points with high usage and efficiency; able to self-create off P&R or in isolation.",
  },
  [ReportScore.key.PASSING]: {
    label: "Passing",
    description:
      "Ability to see the entire floor and distribute the ball with pace, timing, and accuracy; can create advantages to set up teammates in position to score; limits turnovers.",
  },
  [ReportScore.key.PR_PLAYMAKING]: {
    label: "P/R Playmaking",
    description:
      "Ability to create for himself or others as a primary P&R ball-handler; plays with poise, pace, and shows ability to navigate different ball screen coverages.",
  },
  [ReportScore.key.FINISHING]: {
    label: "Finishing",
    description:
      "High volume, high efficiency finisher around the rim; capable of finishing above the rim or with touch; can finish through contact and/or traffic; plays with force or with creativity around the rim.",
  },
  [ReportScore.key.OFFENSIVE_REBOUNDING]: {
    label: "Offensive Rebounding",
    description:
      "Reads ball off the rim, creates extra possessions; great timing, strength, and hands; comfortable in traffic; willing crasher, will give multiple efforts and compete for rebounds out of his area.",
  },
  [ReportScore.key.PR_SCREENING]: {
    label: "P/R Screening",
    description:
      "Understands when to set or slip screens; capable of setting physical screens; able to score or create shots for others after receiving pass.",
  },
  [ReportScore.key.CS_SHOOTING]: {
    label: "C&S Shooting",
    description:
      "Shot-ready with feet set and quick release; efficient spot-up shooter; demands defensive “gravity” to create space on floor.",
  },
  [ReportScore.key.MOVEMENT_SHOOTING]: {
    label: "Movement Shooting",
    description:
      "Capable of making shots off movement; efficient footwork and speed to create space off the ball; will run and shoot in transition; sprints to open areas on court for shots.",
  },
  [ReportScore.key.OFF_DRIBBLE_SHOOTING]: {
    label: "Off-Dribble Shooting",
    description:
      "Capable of creating and making shots off the dribble; creates space and gets to spots with his handle; quick pull-up mechanics.",
  },
  [ReportScore.key.ON_BALL_DEFENSE]: {
    label: "On-ball",
    description:
      "Ability to contain penetration/keep the ball out of the paint; guards without fouling; can get into man/disrupt flow; effective closing out to contain or contest.",
  },
  [ReportScore.key.PR_DEFENSE]: {
    label: "Pick and Roll",
    description:
      "Guards: Ability to get through/avoid screens and get back in front of man or contest from behind. Bigs: Ability to execute drop, switch (‘red’), up-to-touch, and/or hard hedge.",
  },
  [ReportScore.key.DEFENSIVE_VERSATILITY]: {
    label: "Versatility",
    description:
      "Ability to effectively guard multiple positions at a high level; not targeted when switch occurs.",
  },
  [ReportScore.key.OFF_BALL_DEFENSE]: {
    label: "Off-ball",
    description:
      "Has weakside anticipation and awareness to deter drives; rotates and recovers effectively; remains active and engaged in help.",
  },
  [ReportScore.key.DEFENSIVE_REBOUNDING]: {
    label: "Rebounding",
    description:
      "High success rate due to timing, physicality, and multiple efforts; does work early and pursues ball, willing rebounder outside his area; exudes mental and physical toughness.",
  },
  [ReportScore.key.RIM_PROTECTION]: {
    label: "Rim Protection",
    description:
      "Has timing and anticipation to block or alter shots; deters shots at the rim.",
  },
  [ReportScore.key.BASKETBALL_IQ]: {
    label: "Basketball IQ",
    description:
      "Shows situational awareness, self-awareness, and cognitive basketball ability; makes the right play on both ends.",
  },
  [ReportScore.key.VERTICAL_ATHLETICISM]: {
    label: "Vertical Athleticism",
    description:
      "High-level ability to vertically leap off one or two feet; explosive jumping in traffic or in space.",
  },
  [ReportScore.key.AGILITY]: {
    label: "Agility",
    description:
      "Ability to move feet laterally, run with speed, and create separation.",
  },
  [ReportScore.key.DEFEND_POINT_GUARD]: {
    label: "PG",
    description: "",
  },
  [ReportScore.key.DEFEND_GUARD]: {
    label: "G",
    description: "",
  },
  [ReportScore.key.DEFEND_WING]: {
    label: "W",
    description: "",
  },
  [ReportScore.key.DEFEND_FORWARD]: {
    label: "F",
    description: "",
  },
  [ReportScore.key.DEFEND_BIG]: {
    label: "B",
    description: "",
  },
};

export const ReportCanDefendKeys = [
  ReportScore.key.DEFEND_POINT_GUARD,
  ReportScore.key.DEFEND_GUARD,
  ReportScore.key.DEFEND_WING,
  ReportScore.key.DEFEND_FORWARD,
  ReportScore.key.DEFEND_BIG,
];

export const ReportPositionLabels = {
  [ReportResponseItem.position.POINT_GUARD]: "Point Guard (PG)",
  [ReportResponseItem.position.GUARD]: "Guard (G)",
  [ReportResponseItem.position.WING]: "Wing (W)",
  [ReportResponseItem.position.FORWARD]: "Forward (F)",
  [ReportResponseItem.position.BIG]: "Big (B)",
};

export const ReportRoleLabels = {
  [ReportResponseItem.role.PLAYMAKER]: "Playmaker (PM)",
  [ReportResponseItem.role.SHOOTER]: "Shooter (SH)",
  [ReportResponseItem.role.PERIMETER_SCORER]: "Perimeter Scorer (PS)",
  [ReportResponseItem.role.SLASHER_DRIVER]: "Slasher/Driver (SD)",
  [ReportResponseItem.role.INTERIOR_FINISHER]: "Interior Finisher (IF)",
  [ReportResponseItem.role.PERIMETER_DEFENDER]: "Perimeter Defender (PD)",
  [ReportResponseItem.role.INTERNAL_DEFENDER]: "Interior Defender (ID)",
};

export const ApolloGradeScopeLabels = {
  [ReportApolloGrade.scope.BASEMENT]: "Basement",
  [ReportApolloGrade.scope.BULLSEYE]: "Bullseye",
  [ReportApolloGrade.scope.CEILING]: "Ceiling",
  [ReportApolloGrade.scope.CURRENT]: "Grade",
  [ReportApolloGrade.scope.REMAINING_CAPACITY]: "Remaining Capacity",
};

export const ApolloGradeValueLabels = {
  [ReportApolloGrade.value.FRANCHISE]: "Franchise",
  [ReportApolloGrade.value.ALL_STAR]: "All Star",
  [ReportApolloGrade.value.ALL_STAR_PLUS]: "All Star Plus",
  [ReportApolloGrade.value.STARTER]: "Starter",
  [ReportApolloGrade.value.TOP_ROTATION]: "Top Rotation",
  [ReportApolloGrade.value.ROTATION]: "Rotation",
  [ReportApolloGrade.value.ROSTER]: "Roster",
  [ReportApolloGrade.value.MINOR_LEAGUE]: "Minor League",
};

export const PlayerAttributeLabels = {
  [ReportPlayerAttribute.key.TEAMMATE]: "Teammate",
  [ReportPlayerAttribute.key.COMPETES]: "Competes",
  [ReportPlayerAttribute.key.WINNING_PLAYS]: "Winning Plays",
  [ReportPlayerAttribute.key.UPSIDE]: "Upside",
};

export const ReportScoutingLocationLabels = {
  [ReportResponseItem.scoutLocation.ON_SITE]: "On-site",
  [ReportResponseItem.scoutLocation.VIDEO]: "Video review",
};

export const ReportTeamFitLabels = {
  [ReportResponseItem.teamFit.YES]: "Yes",
  [ReportResponseItem.teamFit.NO]: "No",
  [ReportResponseItem.teamFit.NA]: "NA",
};
