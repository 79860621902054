import {
  ReportApolloGrade,
  ReportResponseItem,
} from "@suns/api/generated-client/apollo";
import { Badge, Button, cn, Flex, Text } from "@suns/design-system";
import {
  ApolloGradeValueLabels,
  ReportPositionLabels,
  ReportRoleLabels,
} from "../../reports-const";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { Link } from "react-router-dom";
import { URL } from "@/shared/utils/route-constant";
import { useAccount } from "@/shared/hooks";

dayjs.extend(advancedFormat);

interface ReportListItemParams {
  report: ReportResponseItem;
  className?: string;
}

function renderReportType(report: ReportResponseItem) {
  if (report.type === ReportResponseItem.type.PHASE) {
    return `Phase Report`;
  } else if (report.type === ReportResponseItem.type.GENERAL) {
    const opponents = report.games?.map((game) => {
      return game.homeTeam?.id === report.teamId
        ? `vs ${game.awayTeam?.fullName}`
        : `vs ${game.homeTeam?.fullName}`;
    });

    if (opponents) {
      return opponents.join(", ");
    } else {
      return "Player Report";
    }
  }
}

function renderCurrentScore(report: ReportResponseItem) {
  const currentScore = report.apolloGrades?.find(
    (grade) => grade.scope === ReportApolloGrade.scope.CURRENT
  );
  if (currentScore) {
    return (
      <Badge
        className={cn(
          "h-7 text-nowrap text-white",
          apolloGradeColor(currentScore.value)
        )}
      >
        {ApolloGradeValueLabels[currentScore.value]}
      </Badge>
    );
  }

  return null;
}

function apolloGradeColor(grade: ReportApolloGrade.value) {
  switch (grade) {
    case ReportApolloGrade.value.FRANCHISE:
      return "bg-amber-400";
    case ReportApolloGrade.value.ALL_STAR_PLUS:
      return "bg-amber-500";
    case ReportApolloGrade.value.ALL_STAR:
      return "bg-orange-400";
    case ReportApolloGrade.value.STARTER:
      return "bg-orange-500";
    case ReportApolloGrade.value.TOP_ROTATION:
      return "bg-orange-600";
    case ReportApolloGrade.value.ROTATION:
      return "bg-fuchsia-700";
    case ReportApolloGrade.value.ROSTER:
      return "bg-purple-700";
    case ReportApolloGrade.value.MINOR_LEAGUE:
      return "bg-purple-900";
  }
}

function renderPosition(report: ReportResponseItem) {
  if (report.position) {
    return (
      <Badge className="h-7 text-nowrap">
        {ReportPositionLabels[report.position]}
      </Badge>
    );
  }

  return null;
}

function renderRole(report: ReportResponseItem) {
  if (report.role) {
    return (
      <Badge className="h-7 text-nowrap">{ReportRoleLabels[report.role]}</Badge>
    );
  }

  return null;
}

function renderNotes(report: ReportResponseItem) {
  if (report.teamFitNotes) {
    return report.teamFitNotes;
  }

  return null;
}

export default function ReportListItem({
  report,
  className,
}: ReportListItemParams) {
  const account = useAccount();

  const firstGame = report.games?.length > 0 ? report.games[0] : null;
  let editLink = `/${URL.REPORTS.path}/${URL.REPORTS.children.EDIT_REPORT.path}/${report.id}`;
  if (
    firstGame &&
    report.authorUsername === account.info?.username &&
    report.type === ReportResponseItem.type.GENERAL
  ) {
    editLink = `/${URL.REPORTS.path}/${URL.REPORTS.children.CREATE_REPORT.path}/game/${firstGame.id}?playerId=${report.player.id}`;
  }

  return (
    <Flex className={className} direction="down" gap="sm">
      <Text size="md" heading muted>
        {renderReportType(report)}
      </Text>
      <Link
        className="w-96"
        to={`/${URL.REPORTS.path}/${URL.REPORTS.children.VIEW_REPORT.path}/${report.id}`}
      >
        <Text size="2xl" heading>
          {report.player.firstName} {report.player.lastName}
        </Text>
      </Link>
      <Flex direction="right" gap="sm" wrap>
        {renderCurrentScore(report)}
        {renderPosition(report)}
        {renderRole(report)}
      </Flex>
      <>{renderNotes(report)}</>
      <Flex direction="right" gap="sm">
        <Link
          to={`/${URL.REPORTS.path}/${URL.REPORTS.children.VIEW_REPORT.path}/${report.id}`}
        >
          <Button size="sm" variant="outline">
            View
          </Button>
        </Link>
        <Link to={editLink}>
          <Button size="sm" variant="outline">
            Edit
          </Button>
        </Link>
      </Flex>
      <Flex direction="right" gap="xs">
        <Text heading muted>
          {dayjs(report.createdAt).format("MMMM Do YYYY")}
        </Text>
        <Text muted>by</Text>
        <Text heading muted>
          {report.authorName}
        </Text>
      </Flex>
    </Flex>
  );
}
