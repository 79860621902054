import { apolloApi } from "@/shared/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { GameRow, LeagueRow } from "@/swagger";
import { useEffect, useState } from "react";

dayjs.extend(utc);
dayjs.extend(timezone);

export function useReportSelect(date: Date) {
  const [selectedLeague, setSelectedLeague] = useState<LeagueRow>();
  const { fetchLeagues, leagues } = useLeagues();
  const { fetchGames, games } = useGames({
    date,
    leagueId: selectedLeague?.id,
    leagueName: selectedLeague?.name,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    fetchLeagues().catch((error) => setError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!leagues) return;
    setSelectedLeague(leagues[0]);
  }, [leagues]);

  useEffect(() => {
    if (!selectedLeague) return;
    setLoading(true);
    fetchGames()
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLeague, date]);

  return {
    leagues,
    selectedLeague,
    setSelectedLeague,
    games,
    loading,
    error,
  };
}

function useLeagues() {
  const [leagues, setLeagues] = useState<LeagueRow[]>([]);

  const fetchLeagues = async () => {
    const response = await apolloApi.getLeagues({});
    setLeagues(response.leagues);
  };

  return { fetchLeagues, leagues };
}

function useGames(params: {
  date: Date;
  leagueId?: number;
  leagueName?: string;
}) {
  const [games, setGames] = useState<GameRow[]>([]);

  const fetchGames = async () => {
    if (!params.leagueId) return;
    if (!params.leagueName) return;

    setGames(await fetchApolloGames(params.date, params.leagueId));
  };

  return { fetchGames, games };
}

async function fetchApolloGames(date: Date, leagueId: number) {
  const response = await apolloApi.getGames({
    leagueId: leagueId,
    gameDate: date.toUTCString(),
  });
  const games = response.games?.sort((a, b) =>
    dayjs(a.gameTimeUTC).diff(dayjs(b.gameTimeUTC))
  );

  return games;
}
