import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Container, Flex, SkeletonText } from "@suns/design-system";
import { ReportResponseItem } from "@suns/api/generated-client/apollo";
import { PlayerHeader, PlayerHeaderSkeleton } from "@/components";
import { useAccount, useAsync } from "@/shared/hooks";
import { apolloApi } from "@/shared/api";
import { createPlayerReportLoader } from "./loaders/create-player-report-loader";
import {
  reportFormDataToUpsertParams,
  reportResponseToFormData,
} from "../report-utils";
import {
  ReportForm,
  ReportFormSchema,
} from "../components/ReportForm/ReportForm";
import { notify } from "@/components/bugsnag";
import Page from "@/components/Page/Page";
import { SunsApiError } from "@suns/api";
import {
  toastError,
  toastInfo,
  toastSuccess,
} from "@/shared/utils/toast-messages";

export function CreatePlayerReport() {
  const navigate = useNavigate();
  const { playerId } = useParams();
  const account = useAccount();

  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [savedReportId, setSavedReportId] = useState<number>();

  const { loading, response, error } = useAsync(createPlayerReportLoader, {
    playerId: playerId || "",
    authorUsername: account.info?.username ? [account.info.username] : [],
  });

  return (
    <Page
      title={
        response?.report
          ? `${response.report.player.firstName} ${response.report.player.lastName}`
          : "Create Player Report"
      }
      loading={loading}
      loadingState={<CreatePlayerReportLoading />}
      render={() => {
        if (error) {
          throw new SunsApiError("There was an error loading the player", {
            cause: error,
          });
        }

        if (!response || !playerId || !account.info) {
          throw Error("Unable to get a response for the player report.");
        }

        const { report, player, team } = response;

        async function handleSubmit(
          reportFormData: ReportFormSchema,
          publish: boolean = false,
          reportId?: number
        ) {
          if (publish) {
            setIsPublishing(true);
          } else {
            setIsSaving(true);
          }

          try {
            const upsertParams = reportFormDataToUpsertParams(reportFormData);
            upsertParams.id = report?.id ?? savedReportId ?? reportId;
            const savedReportResponse =
              await apolloApi.saveReport(upsertParams);
            setSavedReportId(savedReportResponse.report.id);

            if (publish) {
              toastSuccess("Report Published");
              navigate(-1);
            } else {
              toastInfo("Report Saved");
            }

            return savedReportResponse.report.id;
          } catch (e) {
            notify(new Error("Error saving report.", { cause: e }));
            toastError(
              `Unable to ${publish ? "publish" : "save"} report. Please try again.`
            );
          } finally {
            setIsSaving(false);
            setIsPublishing(false);
          }
        }

        const parsedReport = reportResponseToFormData(
          player,
          account.info,
          ReportResponseItem.type.GENERAL,
          [],
          report
        );

        return (
          <Container size="xl">
            <Card>
              <Flex direction="down" gap="lg">
                <PlayerHeader
                  player={player}
                  teamNbaId={team?.[0]?.nbaId ?? undefined}
                  teamName={team?.[0]?.name}
                  teamImage={team?.[0]?.image ?? undefined}
                  leagueId={team?.[0]?.domesticLeagueId}
                />
                <ReportForm
                  key={report?.id}
                  onSubmit={handleSubmit}
                  isSaving={isSaving}
                  isPublishing={isPublishing}
                  report={parsedReport}
                />
              </Flex>
            </Card>
          </Container>
        );
      }}
    ></Page>
  );
}

function CreatePlayerReportLoading() {
  return (
    <Container size="xl">
      <Card className="grid grid-cols-4 space-x-8">
        <Flex direction="down" gap="lg">
          <PlayerHeaderSkeleton />
          <SkeletonText rows={2} />
          <SkeletonText rows={3} />
        </Flex>
      </Card>
    </Container>
  );
}
