import {
  ReportQueryParams,
  ReportResponseItem,
} from "@suns/api/generated-client/apollo";
import { apolloApi } from "@/shared/api";

export async function editReportLoader({ reportId }: { reportId: string }) {
  const { report } = await apolloApi.getReport(parseInt(reportId, 10));

  if (report.type == ReportResponseItem.type.PHASE) {
    // get all published reports for the player by the author
    const associatedReports = await apolloApi.getReports({
      authorUsername: [report?.authorUsername ?? ""],
      status: ReportQueryParams.status.PUBLISHED,
      type: ReportResponseItem.type.GENERAL,
      playerId: `${report?.playerId}`,
      limit: 1000,
    });

    return {
      report,
      availableReports: associatedReports.reports,
    };
  }

  return { report };
}
