import { useAsync } from "@/shared/hooks";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  PlayerHeaderSkeleton,
  useNavigateLogin,
  TeamLogo,
  PlayerHeadshot,
} from "@/components";
import { URL } from "@/shared/utils/route-constant";

import {
  Button,
  Card,
  Flex,
  Text,
  TextEditor,
  Badge,
  Container,
  Skeleton,
  SkeletonText,
} from "@suns/design-system";

import { PencilIcon, PrinterIcon, TrashIcon } from "@suns/design-system/icons";
import Page from "@/components/Page/Page";
import dayjs from "dayjs";
import { intelByIdLoader } from "../intel-edit/loaders/intel-edit-loader";
import { SunsApiError } from "@suns/api";
import { toast } from "@/shared/utils/toaster";
import { apolloApi } from "@/shared/api";
import { ToastType } from "@/shared/utils/toaster";
import { PlayerRow, TeamRow } from "@suns/api/generated-client/apollo";
import { usePrompt } from "@/components/Prompt/Prompt";

export function IntelView() {
  const { intelId } = useParams();
  const navigate = useNavigate();
  const navigateLogin = useNavigateLogin();
  const prompt = usePrompt();
  const {
    response: intel,
    loading: intelLoading,
    error: intelError,
  } = useAsync(intelByIdLoader, {
    intelId: intelId ? Number(intelId) : undefined,
  });

  const handleDelete = async () => {
    const deleteConfirmation = await prompt(
      "Are you sure you want to delete this intel?",
      ""
    );

    if (deleteConfirmation) {
      try {
        await apolloApi.deleteIntel(Number(intelId));
        toast(ToastType.SUCCESS, "Intel deleted");
        navigate(-1);
      } catch (e) {
        if (e instanceof SunsApiError && e.authError) {
          toast(ToastType.ERROR, "Please log back in to delete.");
          navigateLogin();
        }
        toast(ToastType.ERROR, "Error deleting report.");
      }
    }
  };

  const title = [...(intel?.players || []), ...(intel?.teams || [])]
    .map((item) =>
      "firstName" in item ? `${item.firstName} ${item.lastName}` : item.fullName
    )
    .join(", ");

  function PlayerHeader({ player }: { player: PlayerRow }) {
    return (
      <Flex gap="sm" align="center">
        <PlayerHeadshot
          playerId={player.nbaId || player.id}
          leagueId={player.currentTeams?.[0]?.domesticLeagueId}
          src={player.image}
        />
        <Flex direction="down">
          <Text size="lg" heading>
            {`${player.firstName} ${player.lastName}`}
          </Text>

          <Flex align="center" gap="xs">
            <TeamLogo
              size="xxs"
              teamId={
                player.currentTeams?.[0]?.nbaId || player.currentTeams?.[0]?.id
              }
              leagueId={player.currentTeams?.[0]?.domesticLeagueId}
              image={player.currentTeams?.[0]?.image}
            />
            <Text>{player.currentTeams?.[0]?.name}</Text>
          </Flex>
        </Flex>
      </Flex>
    );
  }

  function TeamHeader({ team }: { team: TeamRow }) {
    return (
      <Flex gap="sm" align="center">
        <TeamLogo
          // size="lg"
          teamId={team.nbaId || team.id}
          leagueId={team.domesticLeagueId}
          image={team.image}
        />
        <Text size="lg" heading>
          {team.fullName}
        </Text>
      </Flex>
    );
  }

  return (
    <Page
      title={title}
      loading={intelLoading}
      loadingState={<IntelViewLoading />}
      render={() => {
        if (intelError) {
          throw Error("There was an error requesting intel.", {
            cause: intelError,
          });
        }

        return (
          <Container size="xl">
            <Card>
              <Flex direction="down" align="center" className="w-full">
                <Flex direction="down" className="max-w-4xl" gap="md">
                  <Flex direction="down" gap="sm">
                    <Text heading size="2xl">
                      Intel
                    </Text>
                    <Flex direction="right" gap="xs" className="text-muted-500">
                      <Text heading size="sm">
                        {intel?.authorName}
                      </Text>
                      <Text size="sm">on</Text>
                      <Text heading size="sm">
                        {dayjs(intel?.createdAt).format("MMMM Do YYYY")}
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex gap="lg" wrap>
                    {intel?.players.map((player) => (
                      <PlayerHeader key={player.id} player={player} />
                    ))}
                    {intel?.teams.map((team) => (
                      <TeamHeader key={team.id} team={team} />
                    ))}
                  </Flex>

                  {(intel?.tags?.length || 0) > 0 && (
                    <Flex gap="sm" wrap>
                      {intel?.tags?.map((tag) => {
                        return (
                          <Badge
                            variant="secondary"
                            className="rounded-md px-2 font-normal"
                            key={tag}
                          >
                            {tag}
                          </Badge>
                        );
                      })}
                    </Flex>
                  )}

                  <Flex gap="lg" align="start">
                    {[
                      ["Source", intel?.source],
                      ["Trustworthiness", intel?.trustworthiness],
                    ].map(([title, value]) => (
                      <Flex key={title} direction="down">
                        <Text size="lg" muted heading>
                          {title}
                        </Text>
                        <Text size="lg" heading>
                          {value}
                        </Text>
                      </Flex>
                    ))}
                  </Flex>
                  <TextEditor
                    defaultValue={intel?.notes ?? undefined}
                    readonly
                  />
                  <Flex direction="right" gap="sm" className="print:hidden">
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        window.print();
                      }}
                    >
                      <PrinterIcon size={16} />
                    </Button>
                    <Link
                      to={`/reports/${URL.INTEL.path}/${URL.INTEL.children.INTEL_EDIT.path}/${intelId}`}
                    >
                      <Button type="button" variant="outline">
                        <Flex direction="right" align="center" gap="sm">
                          <PencilIcon size={16} />
                          Edit
                        </Flex>
                      </Button>
                    </Link>

                    <Button
                      type="button"
                      variant="outline"
                      onClick={handleDelete}
                    >
                      <TrashIcon size={16} />
                      Delete
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </Card>
          </Container>
        );
      }}
    />
  );
}

function IntelViewLoading() {
  return (
    <Container size="xl">
      <Card>
        <Flex direction="down" align="center" className="w-full">
          <Flex direction="down" className="w-3/4" gap="md">
            <Flex direction="down" gap="sm" className="mb-4">
              <Flex direction="down" gap="md">
                <Skeleton className="h-8 w-24" />
                <Skeleton className="h-4 w-72" />
              </Flex>
            </Flex>
            <PlayerHeaderSkeleton />
            <Flex gap="lg" align="center" className="mt-4">
              <Flex direction="down" gap="md">
                <Skeleton className="h-4 w-36" />
                <Skeleton className="h-4 w-24" />
              </Flex>
              <Flex direction="down" gap="md">
                <Skeleton className="h-4 w-36" />
                <Skeleton className="h-4 w-24" />
              </Flex>
            </Flex>
            <Skeleton className="my-2 h-5 w-24" />
            <Flex direction="down" gap="sm">
              <SkeletonText rows={1} className="h-4 w-full" />
              <SkeletonText rows={1} className="h-4 w-full" />
              <SkeletonText rows={1} className="h-4 w-64" />
            </Flex>
            <Skeleton className="h-10 w-20" />
          </Flex>
        </Flex>
      </Card>
    </Container>
  );
}
