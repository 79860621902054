import { CommandEmpty, Command as CommandPrimitive } from "cmdk";
import { useEffect, useState } from "react";
import { Command, CommandGroup, CommandItem, CommandList } from "cmdk";
import { Badge, cn, Flex, Input } from "@suns/design-system";
import {
  Popover,
  PopoverAnchor,
  PopoverContent,
} from "@suns/design-system/src/components/Popover/Popover";
import { PlayerCacheItem } from "@suns/api/generated-client/apollo/models/PlayerCacheItem";
import { Separator } from "@suns/design-system/src/components/Separator/Separator";
import Fuse from "fuse.js";

type Props = {
  players: PlayerCacheItem[];
  loading: boolean;
  onSelectedValue: (value: string | null) => void;
  defaultSelectedPlayer?: PlayerCacheItem | null;
  className?: string;
};

export function PlayerSearch({
  players,
  loading,
  onSelectedValue,
  defaultSelectedPlayer,
  className,
}: Props) {
  const placeholder = "Enter player name";
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerCacheItem | null>(
    defaultSelectedPlayer ?? null
  );
  const fuse = new Fuse(players, {
    keys: ["searchKey"],
  });
  const filteredPlayers = fuse
    .search(searchValue || "a")
    .map((result) => result.item)
    .slice(0, 7);

  const onSelectItem = (inputValue: string, player: PlayerCacheItem) => {
    onSelectedValue(inputValue);
    setSearchValue("");
    setSelectedPlayer(player);
    setOpen(false);
  };

  const getNextIndex = (
    e: React.KeyboardEvent<HTMLInputElement>,
    hoveredIndex: number | null,
    length: number
  ): number => {
    if (e.key === "ArrowUp" || (e.key === "Tab" && e.shiftKey)) {
      return hoveredIndex === null
        ? length - 1
        : (hoveredIndex - 1 + length) % length;
    } else {
      return hoveredIndex === null ? 0 : (hoveredIndex + 1) % length;
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (open) {
      if (e.key === "Tab" || e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();
        if (filteredPlayers.length > 0) {
          const nextIndex = getNextIndex(
            e,
            hoveredIndex,
            filteredPlayers.length
          );
          setHoveredIndex(nextIndex);
        }
      } else if (e.key === "Enter" && hoveredIndex !== null) {
        e.preventDefault();
        const selectedPlayer = filteredPlayers[hoveredIndex];
        onSelectItem(String(selectedPlayer.id), selectedPlayer);
      }
    }
  };

  useEffect(() => {
    setSelectedPlayer(defaultSelectedPlayer ?? null);
  }, [defaultSelectedPlayer]);

  useEffect(() => {
    if (!selectedPlayer) {
      setSearchValue("");
      setSelectedPlayer(null);
    }
  }, [selectedPlayer]);

  return (
    <Flex align="center" className="max-w-full">
      <Popover open={open} onOpenChange={setOpen}>
        <Command
          shouldFilter={false}
          className={cn(
            className !== "list"
              ? "w-[400px] sm:max-w-full"
              : "w-[300px] xs:max-w-full"
          )}
        >
          <PopoverAnchor>
            <CommandPrimitive.Input
              asChild
              value={
                searchValue !== ""
                  ? searchValue
                  : selectedPlayer
                    ? `${selectedPlayer.lastName}, ${selectedPlayer.firstName}`
                    : ""
              }
              onValueChange={(value) => {
                setSearchValue(value);
                if (value === "") {
                  setSelectedPlayer(null);
                  onSelectedValue(null);
                }
              }}
              onKeyDown={onKeyDown}
              onFocus={() => setOpen(true)}
            >
              <Input type="text" placeholder={placeholder} />
            </CommandPrimitive.Input>
          </PopoverAnchor>
          {!open && <CommandList aria-hidden="true" className="hidden" />}
          <PopoverContent
            asChild
            avoidCollisions={false}
            onOpenAutoFocus={(e) => e.preventDefault()}
            onInteractOutside={(e) => {
              if (
                e.target instanceof Element &&
                e.target.hasAttribute("cmdk-input")
              ) {
                e.preventDefault();
              }
            }}
            className="w-[--radix-popover-trigger-width] p-0"
          >
            <CommandList>
              <CommandGroup>
                {filteredPlayers.map((player, index) => (
                  <CommandItem
                    key={player.id}
                    value={String(player.id)}
                    onKeyDown={(e) => setOpen(e.key !== "Escape")}
                    onSelect={() => {
                      onSelectItem(String(player.id), player);
                    }}
                    className={cn(
                      "cursor-pointer",
                      hoveredIndex == index && "bg-gray-200"
                    )}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <Flex
                      gap="sm"
                      align="center"
                      className="px-3 py-2 text-sm md:text-base"
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      {`${player.lastName}, ${player.firstName}`}
                      <Badge muted>{player.teamCode || "FA"}</Badge>
                    </Flex>
                    <Separator
                      className={cn(
                        "my-0",
                        index == filteredPlayers.length - 1 && "hidden"
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
              {!loading ? (
                <CommandEmpty className="px-3 py-2">
                  {"Loading players..."}
                </CommandEmpty>
              ) : null}
            </CommandList>
          </PopoverContent>
        </Command>
      </Popover>
    </Flex>
  );
}
