import { PlayerSearch } from "@/components";
import { usePlayerList } from "../reports/reports-create/hooks/usePlayerList";
import { useNavigate } from "react-router-dom";
import { SunsApiError } from "@suns/api";
import { Flex } from "@suns/design-system";
import { useAsync } from "@/shared/hooks";
import { playersLoader } from "./loaders/players-loader";
import { PlayerRow } from "@suns/api/generated-client/apollo";
import { DataTable } from "@suns/design-system/src/components/DataTable/DataTable";
import {
  ColumnDef,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { useState } from "react";
import dayjs from "dayjs";

const columns: ColumnDef<PlayerRow>[] = [
  {
    header: "Player Name",
    accessorKey: "name",
    accessorFn: (row: PlayerRow) => row.firstName + " " + row.lastName,
    enableHiding: false,
    enableSorting: false,
  },
  {
    header: "Team",
    accessorKey: "currentTeam",
    accessorFn: (row: PlayerRow) => {
      return row.currentTeams?.[0]?.fullName ?? "Free Agent";
    },
    enableSorting: false,
  },
  {
    header: "Position",
    accessorKey: "position",
    enableSorting: true,
  },
  {
    header: "Age",
    accessorKey: "birthDate",
    accessorFn: (row: PlayerRow) =>
      row.birthDate
        ? dayjs().diff(dayjs(row.birthDate), "year", true).toFixed(1)
        : "--",
    enableSorting: true,
  },
  {
    header: "Height",
    accessorKey: "height",
    accessorFn: (row: PlayerRow) => {
      if (row.height === "0") return "--";

      const parts = row.height?.split("-") ?? [];
      if (parts.length === 2) {
        return `${parts[0]}' ${parts[1]}`;
      } else {
        return row.height;
      }
    },
    enableSorting: true,
  },
  {
    header: "Weight",
    accessorKey: "weight",
    accessorFn: (row: PlayerRow) => (row.weight ? `${row.weight} lbs` : "--"),
    enableSorting: true,
  },
  {
    header: "Wing",
    accessorKey: "wing",
    accessorFn: (row: PlayerRow) => {
      if (!row.wing) return "--";

      const feet = Math.floor(row.wing / 12);
      const remainingInches = row.wing % 12;
      return `${feet}' ${remainingInches}`;
    },
    enableSorting: true,
  },
  {
    header: "Reach",
    accessorKey: "reach",
    accessorFn: (row: PlayerRow) => {
      if (!row.reach) return "--";

      const feet = Math.floor(row.reach / 12);
      const remainingInches = row.reach % 12;
      return `${feet}' ${remainingInches}`;
    },
    enableSorting: true,
  },
];

export function PlayersListings() {
  const {
    response: playerList,
    error: playerListError,
    loading: playerListLoading,
  } = usePlayerList();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useState<SortingState>([]);

  const { response, loading } = useAsync(playersLoader, {
    offset: pagination.pageIndex * pagination.pageSize,
    limit: pagination.pageSize,
    sortColumn: sorting[0]?.id,
    sortDir: sorting[0]?.desc ? "desc" : "asc",
  });

  const handleSelectPlayer = (playerId: string | null) => {
    if (!playerId) return;
    navigate(`${playerId}`);
  };

  if (playerListError) {
    throw new SunsApiError("Unable to load playerList.", {
      cause: playerListError!,
    });
  }

  return (
    <Flex direction="down" gap="md">
      <PlayerSearch
        players={playerList || []}
        loading={playerListLoading}
        onSelectedValue={handleSelectPlayer}
      />
      <DataTable
        loading={loading}
        columns={columns}
        data={response?.players || []}
        count={response?.count || 0}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
        onRowClicked={(row) => handleSelectPlayer(`${row.original.id}`)}
      />
    </Flex>
  );
}
