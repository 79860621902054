import { TeamLogo } from "@/components";
import { useGameSelect } from "@/pages/reports/reports-create/hooks/useGameSelect";
import { useAccount } from "@/shared/hooks";
import { GameRow } from "@suns/api/generated-client/apollo";
import {
  Command,
  Flex,
  FormField,
  Popover,
  PopoverContent,
  Text,
} from "@suns/design-system";
import {
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@suns/design-system/src/components/Command/Command";
import { PopoverTrigger } from "@suns/design-system/src/components/Popover/Popover";
import dayjs from "dayjs";
import { LoaderCircle, PlusCircleIcon, XIcon } from "lucide-react";
import { useState } from "react";
import { useFormState } from "react-hook-form";

export default function MultiGameSelection({
  gameId,
  readonly,
}: {
  gameId?: string;
  readonly: boolean;
}) {
  const account = useAccount();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const formState = useFormState();
  const playerId = formState.defaultValues?.playerId;

  const {
    response: games,
    loading,
    error,
    refresh,
  } = useGameSelect({ playerId, authorUsername: account.info!.username });

  return (
    <Flex direction="down">
      <Text heading size="lg" className="pb-2 pt-8">
        Games
      </Text>
      <FormField
        name="games"
        render={({ field }) => (
          <Flex direction="right" gap="md" wrap>
            {field.value.map((game: GameRow) => (
              <SelectedGame
                game={game}
                key={game.id}
                removable={!readonly && gameId !== `${game.id}`}
                onRemoveGame={(gameId) => {
                  // remove the clicked game
                  const newGames = field.value.filter(
                    (game: GameRow) => game.id !== gameId
                  );
                  field.onChange(newGames);
                }}
              />
            ))}
            {!readonly ? (
              <Popover
                open={popoverOpen}
                onOpenChange={(open) => {
                  setPopoverOpen(open);
                  if (open) {
                    refresh();
                  }
                }}
              >
                <PopoverTrigger>
                  <Flex
                    direction="right"
                    align="center"
                    justify="center"
                    gap="sm"
                    className="h-20 w-56 cursor-pointer rounded-md border border-gray-300 px-8 py-5"
                  >
                    <PlusCircleIcon size={24} color="lightgray" />
                    <Text className="text-gray-400">Add Game</Text>
                  </Flex>
                </PopoverTrigger>
                <PopoverContent className="p-0">
                  <Command>
                    <CommandInput placeholder="Search" />
                    <CommandList>
                      <CommandEmpty>
                        {loading ? (
                          <Flex align="center" justify="center">
                            <LoaderCircle size={18} className="animate-spin" />
                          </Flex>
                        ) : (
                          "No results found."
                        )}
                      </CommandEmpty>
                    </CommandList>
                    <CommandList>
                      {games && !loading && !error && (
                        <CommandGroup>
                          {games
                            .filter(
                              // Filter out games that are already selected
                              (game) =>
                                !field.value.find(
                                  (existingGame: GameRow) =>
                                    existingGame.id === game.id
                                )
                            )
                            .map((availableGame) => (
                              <CommandItem
                                key={availableGame.id}
                                className="border-b"
                                onSelect={() => {
                                  field.onChange([
                                    ...field.value,
                                    availableGame,
                                  ]);
                                  setPopoverOpen(false);
                                }}
                              >
                                <GameOption game={availableGame} />
                              </CommandItem>
                            ))}
                        </CommandGroup>
                      )}
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            ) : null}
          </Flex>
        )}
      />
    </Flex>
  );
}

function SelectedGame({
  game,
  removable,
  onRemoveGame,
}: {
  game: GameRow;
  removable: boolean;
  onRemoveGame: (gameId: number) => void;
}) {
  return (
    <Flex className="relative">
      <Flex
        key={game.id}
        direction="down"
        align="center"
        justify="center"
        gap="sm"
        className="h-20 w-56 rounded-md border border-gray-600"
      >
        <Text size="xs" muted>
          {dayjs(game.gameTimeUTC).format("MMM D, YYYY")}
        </Text>
        <Flex direction="right" align="center" gap="md">
          <Flex direction="right" gap="xs">
            <TeamLogo
              teamId={game.awayTeam?.nbaId}
              image={game.awayTeam?.image}
              leagueId={game.leagueId}
              size="xxs"
            />
            <Text heading>{game.awayTeam?.code}</Text>
          </Flex>
          @
          <Flex direction="right" gap="xs">
            <Text heading>{game.homeTeam?.code}</Text>
            <TeamLogo
              teamId={game.homeTeam?.nbaId}
              image={game.homeTeam?.image}
              leagueId={game.leagueId}
              size="xxs"
            />
          </Flex>
        </Flex>
      </Flex>
      {removable ? (
        <Flex
          className="absolute -right-2 -top-2 cursor-pointer rounded-full border border-gray-600
            bg-white p-1"
          onClick={() => onRemoveGame(game.id)}
        >
          <XIcon size={16} />
        </Flex>
      ) : null}
    </Flex>
  );
}

function GameOption({ game }: { game: GameRow }) {
  return (
    <Flex direction="right" gap="sm" align="center">
      <Text heading>{dayjs(game.gameTimeUTC).format("MMM D, YYYY")}</Text>
      <Text size="sm" muted>
        {game.awayTeam?.code} @ {game.homeTeam?.code}
      </Text>
    </Flex>
  );
}
