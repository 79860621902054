import { apolloApi } from "@/shared/api";
import { useAsync } from "@/shared/hooks";
import { ApolloLeagueId } from "@suns/api/src/params";
import { TeamRow } from "@suns/api/generated-client/apollo";
import { sortTeamsByLeagueAndName } from "@/shared/utils/helper-functions";

export type TeamCacheItem = {
  id: number;
  key: string;
  name: string;
  searchKey: string;
  teamCode: string;
  leagueId: ApolloLeagueId;
  level: TeamRow.level;
};

const createSearchKey = (team: TeamRow) => {
  const searchKey = team.fullName ?? team.name;
  return searchKey.normalize("NFD").replace(/\p{Diacritic}/gu, "");
};

const teamListCacheKey = "teamListCacheV4";
const teamListCacheDateKey = "teamListCacheDateV4";

export function useTeamList() {
  return useAsync(async () => {
    const cachedTeamList = localStorage.getItem(teamListCacheKey) ?? "";

    const now = new Date().getTime();

    if (cachedTeamList) {
      const cachedDate = localStorage.getItem(teamListCacheDateKey);
      const sevenDays = 7 * 24 * 60 * 60 * 1000;

      if (cachedDate && now - parseInt(cachedDate) > sevenDays) {
        localStorage.removeItem(teamListCacheKey);
        localStorage.removeItem(teamListCacheDateKey);
        return refresh();
      }
      return JSON.parse(cachedTeamList) as TeamCacheItem[];
    }

    return refresh();
  });
}

async function refresh(): Promise<TeamCacheItem[]> {
  const now = new Date().getTime();

  const response = await apolloApi.getTeams({});
  const teams = response.teams
    .filter((team) => team.domesticLeagueId !== ApolloLeagueId.WNBA)
    .map((team) => {
      return {
        id: team.id,
        key: team.id.toString(),
        name: team.fullName ?? team.name,
        searchKey: createSearchKey(team),
        teamCode: team.code ?? team.countryCode,
        leagueId: team.domesticLeagueId,
        level: team.level,
      };
    });

  const sortedTeams = sortTeamsByLeagueAndName(teams);

  localStorage.setItem(teamListCacheKey, JSON.stringify(sortedTeams));
  localStorage.setItem(teamListCacheDateKey, now.toString());

  return teams as TeamCacheItem[];
}
