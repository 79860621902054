/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlayerMetadataRow } from './PlayerMetadataRow';
import type { TeamRow } from './TeamRow';
export type PlayerRow = {
    id: number;
    collegeTeam: TeamRow | null;
    currentTeams: Array<TeamRow> | null;
    nbaId: number | null;
    ssId: number | null;
    dxId: number | null;
    firstName: string;
    lastName: string;
    birthDate: string | null;
    homeTown: string | null;
    homeState: string | null;
    homeCountryCode: string | null;
    nationalityCountryCode: string | null;
    highschool: string | null;
    image: string | null;
    level: PlayerRow.level;
    position: string | null;
    height: string | null;
    weight: number | null;
    wing: number | null;
    reach: number | null;
    metadata: PlayerMetadataRow | null;
    createdAt: string;
    updatedAt: string;
};
export namespace PlayerRow {
    export enum level {
        PRO = 'PRO',
        AMATEUR = 'AMATEUR',
    }
}

