import { Button, Flex, SelectOption, Select } from "@suns/design-system";
import { FilterValues } from "../all-reports-listings";
import { X } from "lucide-react";
import { Filter } from "@suns/design-system/src/components/Filter/Filter";
import { PlayerSearch } from "@/components/PlayerSearch/PlayerSearch";
import { PlayerCacheItem } from "@suns/api/generated-client/apollo";
import { FilterSelector } from "@/pages/intel";
import { TeamCacheItem } from "@/components/PlayerTeamSearch/hooks/useTeamList";
import { PlayerLevel } from "@/pages/intel/intel-create-player-team/tags";

interface ReportFiltersProps {
  type: "allReportsIntel" | "myReportsIntel";
  onChange: (filterValues: FilterValues) => void;
  onClear: () => void;
  filterValues: FilterValues;
  teams: TeamCacheItem[];
  authors?: {
    key: string;
    name: string;
  }[];

  loading: boolean;
  playerList: PlayerCacheItem[];
  playerListLoading: boolean;
}

export default function ReportFilters({
  type,
  onChange,
  onClear,
  filterValues,
  teams,
  authors,
  loading,
  playerList,
  playerListLoading,
}: ReportFiltersProps) {
  const player = filterValues.playerId
    ? playerList?.find(
        (player) => player.id.toString() === filterValues.playerId
      )
    : null;

  return (
    <Flex gap="sm" align="center" wrap={true} className="w-full">
      <PlayerSearch
        players={playerList || []}
        loading={playerListLoading}
        onSelectedValue={(value) => {
          onChange({ ...filterValues, playerId: value || undefined });
        }}
        defaultSelectedPlayer={player}
        className="list"
      />

      {type === "allReportsIntel" && (
        <Select
          className="w-36 bg-white"
          value={filterValues.level || PlayerLevel.PRO}
          onValueChange={(value) => onChange({ ...filterValues, level: value })}
        >
          <SelectOption value={PlayerLevel.PRO} id={PlayerLevel.PRO}>
            {PlayerLevel.PRO}
          </SelectOption>
          <SelectOption value={PlayerLevel.AMATEUR} id={PlayerLevel.AMATEUR}>
            {PlayerLevel.AMATEUR}
          </SelectOption>
        </Select>
      )}

      <FilterSelector
        value={filterValues.teamId || []}
        onChange={(selectedKeys: string[]) => {
          onChange({ ...filterValues, teamId: selectedKeys });
        }}
        options={
          teams?.map((team) => ({
            key: team.key,
            searchKey: team.searchKey,
            name: team.name,
            leagueId: team.leagueId,
          })) ?? []
        }
        title="Teams"
      />

      {authors && (
        <Filter
          type="tagFilter"
          avoidCollisions={false}
          title={loading ? "Loading..." : "Author"}
          value={filterValues.authorUsername || []}
          onChange={(value) =>
            onChange({
              ...filterValues,
              authorUsername: value as string[],
            })
          }
          options={authors?.map((author) => ({
            key: author.key,
            name: author.name,
          }))}
        />
      )}
      <Button variant="ghost" onClick={onClear}>
        <Flex gap="xs" align="center" justify="center">
          Reset
          <X className="w-4 pt-0.5" />
        </Flex>
      </Button>
    </Flex>
  );
}
