import {
  ReportResponseItem,
  ReportScore,
} from "@suns/api/generated-client/apollo";
import { Button, Flex } from "@suns/design-system";
import { Link } from "react-router-dom";

interface SkillScoreHistoryParams {
  reports: ReportResponseItem[];
  score: ReportScore.key;
}

export default function SkillScoreHistory({
  reports,
  score,
}: SkillScoreHistoryParams) {
  //only show score history for phase reports
  if (reports.length === 0) {
    return null;
  }

  return (
    <Flex direction="right" gap="xs" className="print:hidden">
      Previous:{" "}
      {reports.map((attachedReport, i) => {
        const reportScore = attachedReport.scores.find(
          (reportScore) => reportScore.key === score
        );
        if (!reportScore) {
          return null;
        }

        return (
          <Link
            to={`/reports/view/${attachedReport.id}`}
            key={attachedReport.id}
          >
            <Button
              variant="link"
              key={reportScore.reportId}
              className="h-auto p-0 text-secondary-600"
            >
              {reportScore.value ?? "NA"}
              {i < reports.length - 1 ? ", " : null}
            </Button>
          </Link>
        );
      })}
    </Flex>
  );
}
