import { IntelRow, IntelUpsertParams } from "@suns/api/generated-client/apollo";
import { IntelFormSchema } from "./components/IntelForm";

export function intelResponseToFormData(intel: IntelRow): IntelFormSchema {
  return {
    id: intel.id,
    playerId: intel.players?.map((player) => player.id),
    teamId: intel.teams?.map((team) => team.id),
    level: intel.level,
    status: intel.status,
    authorUsername: intel.authorUsername,
    authorName: intel.authorName,
    notes: intel.notes,
    source: intel.source,
    trustworthiness: intel.trustworthiness,
    tags: intel.tags,
  };
}

export function intelFormDataToUpsertParams(
  intel: IntelFormSchema
): IntelUpsertParams {
  const { playerId, teamId, ...restIntel } = intel;
  if (!playerId && !teamId) {
    throw new Error("A player or team needs to be set for intel.");
  }

  return {
    ...restIntel,
    playerIds: playerId?.filter((id): id is number => id !== undefined) ?? [],
    teamIds: teamId?.filter((id): id is number => id !== undefined) ?? [],
    id: intel.id || undefined,
    tags: intel.tags ?? undefined,
  };
}
