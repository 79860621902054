import { toast as toastify } from "react-toastify";

export enum ToastType {
  INFO,
  SUCCESS,
  ERROR,
}

type Position =
  | "top-right"
  | "top-center"
  | "top-left"
  | "bottom-right"
  | "bottom-center"
  | "bottom-left";

export function toast(
  type: ToastType,
  message: string,
  duration: number | false = 2000,
  position: Position = "bottom-right"
) {
  let toastFunction = toastify.info;
  switch (type) {
    case ToastType.ERROR:
      toastFunction = toastify.error;
      break;
    case ToastType.SUCCESS:
      toastFunction = toastify.success;
      break;
    case ToastType.INFO:
      toastFunction = toastify.info;
  }

  toastFunction(message, {
    toastId: message,
    position: position,
    autoClose: duration,
    closeButton: duration != false,
    closeOnClick: duration != false,
    hideProgressBar: true,
    theme: "colored",
  });
}
