import { PlayerRow } from "@suns/api/generated-client/apollo";
import { Flex, Text } from "@suns/design-system";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

interface StatCategoryProps {
  label: string;
  value: string;
}

function formatHeight(height: string): string {
  if (height === "0") return "NA";

  const parts = height.split("-");
  if (parts.length === 2) {
    return `${parts[0]}' ${parts[1]}`;
  } else {
    return height;
  }
}

function formatWeight(weight: number): string {
  return `${weight} lbs`;
}

function formatInchesToFeet(inches: number): string {
  const feet = Math.floor(inches / 12);
  const remainingInches = inches % 12;
  return `${feet}' ${remainingInches}`;
}

function formatAge(birthDate: string): string {
  return dayjs().diff(dayjs(birthDate), "year", true).toFixed(1);
}

function StatCategory({ label, value }: StatCategoryProps) {
  return (
    <Flex direction="down">
      <Text size="xs">{label}</Text>
      <Text size="xl" heading>
        {value}
      </Text>
    </Flex>
  );
}

export function PlayerMeasurements({ player }: { player: PlayerRow }) {
  return (
    <Flex gap="lg" verticalGap="md" wrap>
      <StatCategory
        label="Height"
        value={player.height ? formatHeight(player.height) : "NA"}
      />
      <StatCategory
        label="Weight"
        value={player.weight ? formatWeight(player.weight) : "NA"}
      />
      <StatCategory
        label="Age"
        value={player.birthDate ? formatAge(player.birthDate) : "NA"}
      />
      <StatCategory
        label="Wing"
        value={player.wing ? formatInchesToFeet(player.wing) : "NA"}
      />
      <StatCategory
        label="Reach"
        value={player.reach ? formatInchesToFeet(player.reach) : "NA"}
      />
    </Flex>
  );
}
