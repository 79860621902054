import { useMemo, useState } from "react";
import Fuse from "fuse.js";
import { Filter } from "@suns/design-system";
import { useParams } from "react-router-dom";

interface OptionType {
  id?: number;
  key?: string;
  name?: string;
  lastName?: string;
  firstName?: string;
  badgeValue?: string;
  searchKey?: string;
}

interface FilterSelectorProps {
  options: OptionType[];
  value: string[];
  onChange: (selectedKeys: string[]) => void;
  title: string;
}

export function FilterSelector({
  options,
  value,
  onChange,
  title,
}: FilterSelectorProps) {
  const [searchValue, setSearchValue] = useState("");

  const { playerId, teamId } = useParams();

  const mappedOptions = useMemo(
    () =>
      options.map((option) => ({
        key: option.key ?? option.id?.toString() ?? "",
        searchKey: option.searchKey,
        name: option.name ?? `${option.lastName}, ${option.firstName}`,
        badgeValue: option.badgeValue,
      })),
    [options]
  );

  const fuse = useMemo(
    () =>
      new Fuse(mappedOptions, {
        keys: ["searchKey"],
      }),
    [mappedOptions]
  );

  const searchResults =
    searchValue !== ""
      ? fuse
          .search(searchValue)
          .slice(0, 30)
          .map((result) => result.item)
      : mappedOptions.slice(0, 40);

  const selectedOptions = mappedOptions.filter((option) =>
    value?.includes(option.key ?? "")
  );

  const combinedOptions = [...new Set([...selectedOptions, ...searchResults])];

  return (
    <Filter
      type="playerFilter"
      title={title}
      value={value}
      onChange={onChange}
      options={combinedOptions}
      onSearchChange={setSearchValue}
      isDisabled={(optionKey) => optionKey === playerId || optionKey === teamId}
    />
  );
}
