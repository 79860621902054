/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssessmentApolloGrade } from './AssessmentApolloGrade';
import type { PlayerRow } from './PlayerRow';
export type AssessmentResponseItem = {
    id: number;
    playerId: number;
    player: PlayerRow;
    position: AssessmentResponseItem.position | null;
    role: AssessmentResponseItem.role | null;
    authorUsername: string;
    authorName: string;
    apolloGrades: Array<AssessmentApolloGrade> | null;
    updatedAt: string;
    createdAt: string;
};
export namespace AssessmentResponseItem {
    export enum position {
        POINT_GUARD = 'POINT_GUARD',
        GUARD = 'GUARD',
        WING = 'WING',
        FORWARD = 'FORWARD',
        BIG = 'BIG',
    }
    export enum role {
        PLAYMAKER = 'PLAYMAKER',
        SHOOTER = 'SHOOTER',
        PERIMETER_SCORER = 'PERIMETER_SCORER',
        SLASHER_DRIVER = 'SLASHER_DRIVER',
        INTERIOR_FINISHER = 'INTERIOR_FINISHER',
        PERIMETER_DEFENDER = 'PERIMETER_DEFENDER',
        INTERNAL_DEFENDER = 'INTERNAL_DEFENDER',
    }
}

