import { apolloApi } from "@/shared/api";
import { IntelQueryParams } from "@suns/api/generated-client/apollo";

export async function allIntelLoader({
  offset = 0,
  limit = 20,
  level,
  playerIds,
  teamIds,
  authorUsername,
}: {
  offset: number;
  limit: number;
  teamIds?: number[];
  level?: IntelQueryParams.level;
  trustworthiness?: string[];
  authorUsername?: string[];
  playerIds?: string[];
}) {
  const response = await apolloApi.getIntel({
    offset,
    limit,
    level,
    playerIds,
    teamIds,
    authorUsername,
    status: IntelQueryParams.status.PUBLISHED,
  });
  return response;
}

export async function allIntelAuthorUsernamesLoader() {
  const response = await apolloApi.getIntelAuthors();
  return response;
}
